import React from "react";
import { connect } from 'react-redux';
import styled from "styled-components";
import { Button as MuiButton, Menu, MenuItem } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { setLocationWithDispatch } from "../../redux/actions/locationActions";

const Button = styled(MuiButton)(spacing);

class LocationSelector extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderOptions() {
    const { merchant } = this.props;
    const { locations } = merchant;
    return locations.map((location) => {
      return (
        <MenuItem key={location.id} onClick={() => {
          setLocationWithDispatch(location.id);
          this.handleClose();
        }}>{location.name}</MenuItem>
      );
    });
  }

  render() {
    const { anchorEl } = this.state;
    const { merchant, currentLocation } = this.props;
    const location = merchant.locations.find(loc => loc['id'] === currentLocation) || null;
    return (
      <React.Fragment>
        <Button
          mr={2}
          variant="contained"
          size="small"
          color="secondary"
          aria-owns={anchorEl ? "location-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          Location:
          {
            !!location ?
              " " + location.name :
              " N/A"
          }
        </Button>
        <Menu
          id="location-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.renderOptions()}
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  merchant: state.firestore.data.merchant,
  currentLocation: state.location.currentLocation,
});

export default connect(mapStateToProps)(LocationSelector);