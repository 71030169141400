import React, { useState } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  Grid,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  withWidth,
  Chip as MuiChip,
} from "@material-ui/core";
import { AlertCircle } from "react-feather";
import { spacing } from "@material-ui/system";
import MaterialTable from 'material-table';
import Loader from "../../components/Loader";
import CouponDialog from "../../components/dialogs/CouponDialog";
import { getTableOffsetWidth, formatDate } from "../../utils";
import { COUPON_USE_TYPE_ONETIME, DISCOUNT_TYPE_AMOUNT_OFF, DISCOUNT_TYPE_PERCENTAGE_OFF, DISCOUNT_TYPE_BUY_ONE_GET_ONE_FREE } from "../../constants";
import { green, grey } from "@material-ui/core/colors";

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${props => props.rgbcolor};
  color: ${props => props.theme.palette.common.white};
`;
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;

const CouponsEmpty = ({ onCreate }) => {
  return (
    <Card mb={6}>
      <CardContent mt={6} mb={6}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item>
            <AlertCircle size={96} color={"#CED4D9"} />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" gutterBottom>
              You haven't create any coupon yet
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
              Create coupon and send it to your customers today.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mt={3}
              onClick={_ => onCreate(null)}>
              Create a Coupon
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const CouponsTable = ({ records, offset, onRowClick }) => {

  return (
    <TableWrapper offset={offset}>
      <MaterialTable
        columns={[
          { title: 'Code', field: 'code' },
          {
            title: 'Discount', field: 'value', render: record => {
              if (record.discount_type === DISCOUNT_TYPE_AMOUNT_OFF) {
                return `$${record.value} Off`;
              }
              else if (record.discount_type === DISCOUNT_TYPE_PERCENTAGE_OFF) {
                return `${record.value * 100}% Off`;
              }
              else if (record.discount_type === DISCOUNT_TYPE_BUY_ONE_GET_ONE_FREE) {
                return `Buy One Get One Free`;
              }
            }
          },
          {
            title: 'Status', field: 'use_count', render: record => {
              if (record.use_type === COUPON_USE_TYPE_ONETIME) {
                if (record.use_count > 0) {
                  return <Chip label='Used' rgbcolor={green[500]} />;
                } else {
                  return <Chip label='Available' rgbcolor={grey[500]} />;
                }
              }
              return <Chip label={`Used ${record.use_count} Times`} rgbcolor={green[500]} />;
            }
          },
          {
            title: 'Expire Date', field: 'expire_date', render: record => {
              if (record.expire_date) {
                const expire_date = record.expire_date.toDate();
                const date = formatDate(expire_date);
                return date;
              }
              return '';
            }
          },
        ]}
        onRowClick={(event, record) => {
          onRowClick(record);
          // if (record.use_type === COUPON_USE_TYPE_ONETIME)
          //   onRowClick(record);
        }}
        data={records}
        options={{
          toolbar: false,
          // showTitle: false,
          // exportButton: true,
          // exportFileName: 'report',
          // exportAllData: true,
          search: false,
          pageSize: 5,
          pageSizeOptions: [5, 25, 50, 100],
          sorting: false,
        }}
      />
    </TableWrapper>
  );
}

const Coupons = ({ coupons, width }) => {

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  function handleOpenDialog(coupon) {
    setSelectedCoupon(coupon);
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  if (coupons === null)
    return <Loader />;

  // copy records
  const records = [];
  coupons.forEach(coupon => {
    records.push({ ...coupon });
  });

  return (
    <React.Fragment>
      <Grid justify="space-between" container alignItems="center" spacing={6}>
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
            Coupons
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Setting</Typography>
            <Typography>Coupon</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            mt={3}
            onClick={_ => handleOpenDialog(null)}>
            Create New Coupon
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {
            coupons.length === 0 ?
              <CouponsEmpty
                onCreate={handleOpenDialog} /> :
              <CouponsTable
                records={records}
                offset={getTableOffsetWidth(width)}
                onRowClick={handleOpenDialog} />
          }
        </Grid>
      </Grid>
      <CouponDialog
        open={openDialog}
        coupon={selectedCoupon}
        onClose={handleCloseDialog} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const coupons = state.firestore.ordered.coupons;
  if (coupons) {
    return {
      auth: state.firebase.auth,
      coupons,
    }
  }
  return {
    auth: state.firebase.auth,
    coupons: null,
  }
}

export default compose(
  withWidth(),
  connect(mapStateToProps),
  firestoreConnect(props => {
    const { auth } = props;
    return [
      {
        collection: `merchants/${auth.uid}/coupons`,
        orderBy: [['code']],
        storeAs: 'coupons',
      },
    ]
  })
)(Coupons);