import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import themeReducer from './themeReducers';
import dateReducer from './dateReducers';
import locationReducer from './locationReducers';

export default combineReducers({
	theme: themeReducer,
	date: dateReducer,
	location: locationReducer,
	firebase: firebaseReducer,
	firestore: firestoreReducer,
});
