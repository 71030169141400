import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import firbase from '../../firebase';
import rootReducer from '../reducers';

// react-redux-firebase config
const rrfConfig = {
  useFirestoreForProfile: true,
  userProfile: 'users',
  attachAuthIsReady: true,
}

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirestore, getFirebase })),
    reactReduxFirebase(firbase, rrfConfig), // redux bindings for firebase
    reduxFirestore(firbase), // redux bindings for firestore
  ),
);

export default store;
