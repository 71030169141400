import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  InputLabel,
  FormControl,
  Grid,
  FormHelperText,
  Typography,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { uploadImage, deleteImage } from '../../utils';
import DialogTitleWithClose from './DialogTitleWithClose';
import ButtonProgress from "../ButtonProgress";
import ImageFileInput from '../ImageFileInput';
import ImageEditDialog from './ImageEditDialog';
import ErrorSnackbar from '../snackbars/ErrorSnackbar';
import DangerButton from '../buttons/DangerButton';
import ConfirmDialog from './ConfirmDialog';

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;
const TableCellContent = styled(TableCell)`
  min-width: 200px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CategoryForm extends React.Component {

  constructor(props) {
    super(props);

    const { category } = props;

    this.state = {
      name: category ? category.name : '',
      title: category ? category.title : '',
      // items
      selectItems: this.initSelectItems(),
      // edit image
      imageFile: null,
      imageData: null,
      imageDialogOpen: false,
      // delete
      deleteDialogOpen: false,
      // errors
      nameError: false,
      titleError: false,
      showErrorSnackbar: false,
    }
  }

  initSelectItems = () => {
    const { category, items } = this.props;
    return items.map(item => {
      let selectIndex = -1;
      if (category) {
        for (let i = 0; i < category.items.length; i++) {
          if (category.items[i].id === item.id) {
            selectIndex = i + 1;
            break;
          }
        }
      }
      if (selectIndex > 0) {
        return { selected: true, selectIndex, ...item };
      }
      return { selected: false, selectIndex, ...item };
    });
  }

  resetError = () => {
    this.setState({
      nameError: false,
      titleError: false,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { name, title, selectItems, imageFile, imageData } = this.state;
    const { category, onCreateCategory, onUpdateCategory } = this.props;

    this.resetError();
    // check error
    let hasError = false;
    if (!name) {
      this.setState({ nameError: true });
      hasError = true;
    }
    if (!title) {
      this.setState({ titleError: true });
      hasError = true;
    }
    if (hasError) {
      this.setState({ showErrorSnackbar: true });
      return;
    }

    // build items
    const items = selectItems
      .filter(t => t.selected) // remove unselect item
      .sort((a, b) => { return a.selectIndex - b.selectIndex; }) // sort by select index
      .map(t => { return { id: t.id, name: t.name } }); // only required fields

    if (category) {
      onUpdateCategory({
        name,
        title,
        imageFile,
        imageData,
        items,
      });
    } else {
      onCreateCategory({
        name,
        title,
        imageFile,
        imageData,
        items,
      });
    }
  }

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ deleteDialogOpen: true });
  }

  handleDeleteConfirm = () => {
    this.setState({ deleteDialogOpen: false });
    const { onDeleteCategory } = this.props;
    onDeleteCategory();
  }

  handleDeleteCancel = () => {
    this.setState({ deleteDialogOpen: false });
  }

  handleCategoryImageEditCancel = () => {
    this.setState({ imageDialogOpen: false });
  }

  handleCategoryImageEditSuccess = (_, imageData) => {
    this.setState({ imageData, imageDialogOpen: false });
  }

  handleErrorDialogClose = () => {
    this.setState({ showErrorSnackbar: false });
  }

  handleItemImageChange = (_, file) => {
    this.setState({ imageFile: file, imageDialogOpen: true });
  }

  handleCategoryItemSelect = (id) => {
    const { selectItems } = this.state;
    const selectedItems = selectItems.filter(item => item.selected);
    const selectedCount = selectedItems.length;
    let removedIndex = -1;
    const newItems = selectItems.map(item => {
      if (id === item.id) {
        item.selected = !item.selected;
        if (item.selected) {
          item.selectIndex = selectedCount + 1;
        }
        else {
          removedIndex = item.selectIndex;
          item.selectIndex = -1;
        }
      }
      return { ...item };
    });
    if (removedIndex > 0) {
      newItems.forEach(item => {
        if (item.selected && item.selectIndex > removedIndex) {
          item.selectIndex -= 1;
        }
      });
    }
    this.setState({ selectItems: newItems });
  }

  renderItems = () => {
    const { selectItems } = this.state;

    return (
      <TableWrapper>
        <Table>
          <TableBody>
            {selectItems.map(item =>
              <TableRowClickable hover
                key={item.id}
                onClick={_ => { this.handleCategoryItemSelect(item.id); }}>
                <TableCell padding="checkbox">
                  <Checkbox checked={item.selected} />
                </TableCell>
                <TableCell padding="none">
                  {item.selected ? item.selectIndex : ''}
                </TableCell>
                <TableCellContent component="th" scope="row">
                  {item.name}
                </TableCellContent>
                <TableCellContent>
                  {
                    item.modifiers.length > 0 ?
                      item.modifiers.map(m => m.name).join(', ') :
                      'None'
                  }
                </TableCellContent>
              </TableRowClickable>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    );
  }

  containInMenuCount = () => {
    const { category, menus } = this.props;
    if (!category) return 0;
    let count = 0;
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      for (let j = 0; j < menu.categories.length; j++) {
        if (menu.categories[j].id === category.id) {
          count++;
          break;
        }
      }
    }
    return count;
  }

  render() {

    const { category, loading, deleting } = this.props;
    const {
      name,
      title,
      imageDialogOpen,
      imageFile,
      imageData,
      deleteDialogOpen,
      nameError,
      titleError,
      showErrorSnackbar,
    } = this.state;

    const thumbnail = imageData || (category ? category.image : null);
    const count = this.containInMenuCount();

    return (
      <React.Fragment>
        <DialogContent dividers>
          <Grid container spacing={6} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography variant="subtitle2">Details</Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Grid container direction="row" spacing={6} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='name' shrink>Name</InputLabel>
                    <Input
                      id='name'
                      autoFocus
                      placeholder='Category name'
                      value={name}
                      fullWidth
                      readOnly={loading || deleting}
                      error={nameError}
                      onChange={event => this.setState({ name: event.target.value })}
                      onKeyDown={_ => this.setState({ nameError: false })}
                    />
                    <FormHelperText>Category name for your reference only</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='title' shrink>Title</InputLabel>
                    <Input
                      id='title'
                      placeholder='Category title'
                      value={title}
                      fullWidth
                      readOnly={loading || deleting}
                      error={titleError}
                      onChange={event => this.setState({ title: event.target.value })}
                      onKeyDown={_ => this.setState({ titleError: false })}
                    />
                    <FormHelperText>Category title display to your customers</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Grid container
                spacing={0}
                alignItems="center"
                justify="center">
                <Grid item>
                  <ImageFileInput
                    id={0}
                    thumbnail={thumbnail}
                    disabled={loading}
                    onChange={this.handleItemImageChange}
                    iconSize={40}
                    thumbnailSize={66} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Items</Typography>
            </Grid>
            <Grid item xs={12}>
              {this.renderItems()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            category ?
              <DangerButton
                variant="contained"
                disabled={loading || deleting}
                onClick={this.handleDelete}>
                <ButtonProgress loading={deleting} />
                Delete
              </DangerButton> :
              null
          }
          <Button
            onClick={this.handleSubmit}
            variant="outlined"
            color="primary"
            disabled={loading || deleting}>
            <ButtonProgress loading={loading} />
            {category ? "Save Change" : "Create"}
          </Button>
        </DialogActions>
        <ImageEditDialog
          id={0}
          open={imageDialogOpen}
          imageFile={imageFile}
          onClose={this.handleCategoryImageEditCancel}
          onEdit={this.handleCategoryImageEditSuccess}
        />
        <ConfirmDialog
          title={"Delete Category"}
          open={deleteDialogOpen}
          onConfirm={this.handleDeleteConfirm}
          onClose={this.handleDeleteCancel}
          danger
          children={
            <Typography>
              Are you sure you want to delete the <b>{name}</b> category?
              Deleting this category will affect <b>{count} {count > 1 ? 'menus' : 'menu'}</b>.
              This action cannot be undone.
            </Typography>
          }
        />
        <ErrorSnackbar open={showErrorSnackbar}
          message={
            category ?
              "Please fix errors before editing this category" :
              "Please fix errors before creating new category"
          }
          onClose={this.handleErrorDialogClose}
        />
      </React.Fragment>
    );
  }
}

const CategoryDialog = ({ auth, firestore, open, onClose, category, items, menus }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showError, setShowError] = useState(null);

  async function handleCreateCategory(categoryData) {

    setLoading(true);

    const image = await uploadImage(auth.uid, {
      imageData: categoryData.imageData,
      imageFile: categoryData.imageFile,
      imageUrl: '',
    });

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('categories')
      .add({
        title: categoryData.title,
        name: categoryData.name,
        image,
        items: categoryData.items,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleUpdateCategory(categoryData) {

    setLoading(true);

    const image = await uploadImage(auth.uid, {
      imageData: categoryData.imageData,
      imageFile: categoryData.imageFile,
      imageUrl: category.image,
    });

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('categories')
      .doc(category.id)
      .set({
        title: categoryData.title,
        name: categoryData.name,
        image,
        items: categoryData.items,
      }, {
        merge: true,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleDeleteCategory() {

    setDeleting(true);

    // remove it from menus
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      const categories = [];
      for (let j = 0; j < menu.categories.length; j++) {
        if (menu.categories[j].id === category.id) {
          continue;
        }
        categories.push(menu.categories[j]);
      }
      await firestore.collection('merchants')
        .doc(auth.uid)
        .collection('menus')
        .doc(menu.id)
        .update({ categories });
    }

    // delete image
    category.image && await deleteImage({ imageUrl: category.image });

    // remove document
    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('categories')
      .doc(category.id)
      .delete()
      .then(() => {
        setDeleting(false);
        onClose();
      })
      .catch((error) => {
        setDeleting(false);
        setShowError(error.message);
      });
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="category-dialog-title">
      <DialogTitleWithClose id="category-dialog-title"
        disableClose={loading || deleting}
        onClose={onClose}>
        {
          category ?
            "Edit Category" :
            "Create New Category"
        }
      </DialogTitleWithClose>
      <CategoryForm
        category={category}
        items={items}
        menus={menus}
        loading={loading}
        deleting={deleting}
        onCreateCategory={handleCreateCategory}
        onUpdateCategory={handleUpdateCategory}
        onDeleteCategory={handleDeleteCategory} />
      {showError && <ErrorSnackbar open={!!showError}
        message={showError}
        onClose={_ => setShowError(null)}
      />}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(withFirestore(CategoryDialog));