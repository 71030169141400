import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import typography from '../../../src/theme/typography';

const dangerTheme = createMuiTheme({
  palette: {
    primary: {
      main: red[800],
    },
    secondary: {
      main: red[600],
    }
  },
  typography,
});

const DangerButton = ({ children, variant, disabled, onClick }) =>
  <MuiThemeProvider theme={dangerTheme}>
    <Button variant={variant}
      color="primary"
      onClick={onClick}
      disabled={disabled}>
      {children}
    </Button>
  </MuiThemeProvider>;

export default DangerButton;