import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  InputLabel,
  FormControl,
  Grid,
  FormHelperText,
  Typography,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitleWithClose from './DialogTitleWithClose';
import ButtonProgress from "../ButtonProgress";
import ErrorSnackbar from '../snackbars/ErrorSnackbar';
import DangerButton from '../buttons/DangerButton';
import ConfirmDialog from './ConfirmDialog';

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;
const TableCellContent = styled(TableCell)`
  min-width: 200px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MenuForm extends React.Component {

  constructor(props) {
    super(props);

    const { menu } = props;

    this.state = {
      name: menu ? menu.name : '',
      // categories
      selectCategories: this.initSelectCategories(),
      // delete
      deleteDialogOpen: false,
      // errors
      nameError: false,
      showErrorSnackbar: false,
    }
  }

  initSelectCategories = () => {
    const { menu, categories } = this.props;
    return categories.map(category => {
      let selectIndex = -1;
      if (menu) {
        for (let i = 0; i < menu.categories.length; i++) {
          if (menu.categories[i].id === category.id) {
            selectIndex = i + 1;
            break;
          }
        }
      }
      if (selectIndex > 0) {
        return { selected: true, selectIndex, ...category };
      }
      return { selected: false, selectIndex, ...category };
    });
  }

  resetError = () => {
    this.setState({
      nameError: false,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { name, selectCategories } = this.state;
    const { menu, onCreateMenu, onUpdateMenu } = this.props;

    this.resetError();
    // check error
    let hasError = false;
    if (!name) {
      this.setState({ nameError: true });
      hasError = true;
    }
    if (hasError) {
      this.setState({ showErrorSnackbar: true });
      return;
    }

    // build categories
    const categories = selectCategories
      .filter(c => c.selected) // remove unselect item
      .sort((a, b) => { return a.selectIndex - b.selectIndex; }) // sort by select index
      .map(c => { return { id: c.id, name: c.name } }); // only required fields

    if (menu) {
      onUpdateMenu({
        name,
        categories,
      });
    } else {
      onCreateMenu({
        name,
        categories,
      });
    }
  }

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ deleteDialogOpen: true });
  }

  handleDeleteConfirm = () => {
    this.setState({ deleteDialogOpen: false });
    const { onDeleteMenu } = this.props;
    onDeleteMenu();
  }

  handleDeleteCancel = () => {
    this.setState({ deleteDialogOpen: false });
  }

  handleErrorDialogClose = () => {
    this.setState({ showErrorSnackbar: false });
  }

  handleMenuCategorySelect = (id) => {
    const { selectCategories } = this.state;
    const selectedCategories = selectCategories.filter(category => category.selected);
    const selectedCount = selectedCategories.length;
    let removedIndex = -1;
    const newCategoris = selectCategories.map(category => {
      if (id === category.id) {
        category.selected = !category.selected;
        if (category.selected) {
          category.selectIndex = selectedCount + 1;
        }
        else {
          removedIndex = category.selectIndex;
          category.selectIndex = -1;
        }
      }
      return { ...category };
    });
    if (removedIndex > 0) {
      newCategoris.forEach(category => {
        if (category.selected && category.selectIndex > removedIndex) {
          category.selectIndex -= 1;
        }
      });
    }
    this.setState({ selectCategories: newCategoris });
  }

  renderCategories = () => {
    const { selectCategories } = this.state;

    return (
      <TableWrapper>
        <Table>
          <TableBody>
            {selectCategories.map(category =>
              <TableRowClickable hover
                key={category.id}
                onClick={_ => { this.handleMenuCategorySelect(category.id); }}>
                <TableCell padding="checkbox">
                  <Checkbox checked={category.selected} />
                </TableCell>
                <TableCell padding="none">
                  {category.selected ? category.selectIndex : ''}
                </TableCell>
                <TableCellContent component="th" scope="row">
                  {category.name}
                </TableCellContent>
                <TableCellContent>
                  {
                    category.items.length > 0 ?
                      category.items.map(m => m.name).join(', ') :
                      'None'
                  }
                </TableCellContent>
              </TableRowClickable>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    );
  }

  render() {

    const { menu, loading, deleting } = this.props;
    const {
      name,
      deleteDialogOpen,
      nameError,
      showErrorSnackbar,
    } = this.state;

    return (
      <React.Fragment>
        <DialogContent dividers>
          <Grid container spacing={6} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography variant="subtitle2">Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={6} alignItems="center">
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='name' shrink>Name</InputLabel>
                    <Input
                      id='name'
                      autoFocus
                      placeholder='Menu name'
                      value={name}
                      fullWidth
                      readOnly={loading || deleting}
                      error={nameError}
                      onChange={event => this.setState({ name: event.target.value })}
                      onKeyDown={_ => this.setState({ nameError: false })}
                    />
                    <FormHelperText>Menu name for your reference only</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Categories</Typography>
            </Grid>
            <Grid item xs={12}>
              {this.renderCategories()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            menu ?
              <DangerButton
                variant="contained"
                disabled={loading || deleting}
                onClick={this.handleDelete}>
                <ButtonProgress loading={deleting} />
                Delete
              </DangerButton> :
              null
          }
          <Button
            onClick={this.handleSubmit}
            variant="outlined"
            color="primary"
            disabled={loading || deleting}>
            <ButtonProgress loading={loading} />
            {menu ? "Save Change" : "Create"}
          </Button>
        </DialogActions>
        <ConfirmDialog
          title={"Delete Menu"}
          open={deleteDialogOpen}
          onConfirm={this.handleDeleteConfirm}
          onClose={this.handleDeleteCancel}
          danger
          children={<Typography>
            Are you sure you want to delete the <b>{name}</b> menu?
            This action cannot be undone.
          </Typography>}
        />
        <ErrorSnackbar open={showErrorSnackbar}
          message={
            menu ?
              "Please fix errors before editing this menu" :
              "Please fix errors before creating new menu"
          }
          onClose={this.handleErrorDialogClose}
        />
      </React.Fragment>
    );
  }
}

const MenuDialog = ({ auth, firestore, open, onClose, menu, categories }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showError, setShowError] = useState(null);

  async function handleCreateMenu(menuData) {

    setLoading(true);

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('menus')
      .add({
        name: menuData.name,
        categories: menuData.categories,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleUpdateMenu(menuData) {

    setLoading(true);

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('menus')
      .doc(menu.id)
      .set({
        name: menuData.name,
        categories: menuData.categories,
      }, {
        merge: true,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleDeleteMenu() {

    setDeleting(true);

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('menus')
      .doc(menu.id)
      .delete()
      .then(() => {
        setDeleting(false);
        onClose();
      })
      .catch((error) => {
        setDeleting(false);
        setShowError(error.message);
      });
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="menu-dialog-title">
      <DialogTitleWithClose id="menu-dialog-title"
        disableClose={loading || deleting}
        onClose={onClose}>
        {
          menu ?
            "Edit Menu" :
            "Create New Menu"
        }
      </DialogTitleWithClose>
      <MenuForm
        menu={menu}
        categories={categories}
        loading={loading}
        deleting={deleting}
        onCreateMenu={handleCreateMenu}
        onUpdateMenu={handleUpdateMenu}
        onDeleteMenu={handleDeleteMenu} />
      {showError && <ErrorSnackbar open={!!showError}
        message={showError}
        onClose={_ => setShowError(null)}
      />}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(withFirestore(MenuDialog));