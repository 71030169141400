import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from './ContentWrapper';

function Info({ open, onClose, message }) {

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}>
      <SnackbarContentWrapper
        onClose={onClose}
        variant="info"
        message={message}
      />
    </Snackbar>
  );
}

Info.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Info;