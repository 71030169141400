import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase';
import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { AlertCircle } from "react-feather";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitleWithClose from './DialogTitleWithClose';
import ButtonProgress from "../ButtonProgress";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;
const TableCellContent = styled(TableCell)`
  min-width: 200px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ItemList = ({ items, onItemSelect }) => {
  return (
    <TableWrapper>
      <Table>
        <TableBody>
          {items.map(item => (
            <TableRowClickable hover key={item.id}
              onClick={_ => { onItemSelect(item.id); }}>
              <TableCell padding="checkbox">
                <Checkbox checked={item.selected} />
              </TableCell>
              <TableCellContent component="th" scope="row">
                {item.name}
              </TableCellContent>
              <TableCellContent>
                ${item.price}
              </TableCellContent>
            </TableRowClickable>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}

const ItemEmpty = () => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
      <Grid item>
        <AlertCircle size={96} color={"#CED4D9"} />
      </Grid>
      <Grid item>
        <Typography variant="h6" align="center" gutterBottom>
          You haven't create any item yet
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
          Create menu items with names, photos and prices for customer to order.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          component={Link}
          color="primary"
          to="/inventory/items"
          variant="contained">
          Go to Item Page
        </Button>
      </Grid>
    </Grid>
  );
}

// modifier apply to item
const ApplyToItemDialog = ({ auth, firestore, open, onClose, modifier, items }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [selectItems, setSelectItems] = useState(initSelectItems());

  function initSelectItems() {
    return items.map(item => {
      let selected = false;
      for (let i = 0; i < item.modifiers.length; i++) {
        if (item.modifiers[i].id === modifier.id) {
          selected = true;
          break;
        }
      }
      return { selected, ...item };
    });
  }

  function handleItemSelect(id) {
    const newItems = selectItems.map(item => {
      if (id === item.id) {
        item.selected = !item.selected;
      }
      return { ...item };
    });
    setSelectItems(newItems);
  }

  async function handleSubmit() {

    setLoading(true);

    for (let i = 0; i < selectItems.length; i++) {
      const selectItem = selectItems[i];
      let apply = false;
      const modifiers = [];
      for (let k = 0; k < selectItem.modifiers.length; k++) {
        const itemModifier = selectItem.modifiers[k];
        if (modifier.id === itemModifier.id) {
          apply = true;
          if (!selectItem.selected)
            continue;
        }
        modifiers.push({
          id: itemModifier.id,
          name: itemModifier.name,
        });
      }
      if (!apply && selectItem.selected) {
        // add this modifier
        modifiers.push({
          id: modifier.id,
          name: modifier.name,
        });
      }
      await firestore.collection('merchants')
        .doc(auth.uid)
        .collection('items')
        .doc(selectItem.id)
        .set({
          name: selectItem.name,
          title: selectItem.title,
          description: selectItem.description,
          image: selectItem.image,
          price: selectItem.price,
          modifiers,
        });
    }

    setLoading(false);
    onClose();
  }

  function handleClose() {
    setSelectItems(initSelectItems());
    onClose();
  }

  const hasItem = selectItems.length > 0;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="modifier-apply-to-item-dialog-title">
      <DialogTitleWithClose id="modifier-apply-to-item-dialog-title"
        disableClose={loading}
        onClose={handleClose}>
        {`Apply Modifier ${modifier ? modifier.name : ''} to Item`}
      </DialogTitleWithClose>
      <DialogContent dividers={hasItem}>
        {
          hasItem ?
            <ItemList
              items={selectItems}
              onItemSelect={handleItemSelect} /> :
            <ItemEmpty />
        }
      </DialogContent>
      <DialogActions>
        {
          hasItem &&
          <Button
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={handleSubmit}>
            <ButtonProgress loading={loading} />
            Save Change
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(withFirestore(ApplyToItemDialog));