import React from 'react';
import { IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

const DialogTitleWithClose = withStyles(styles)(props => {
  const { children, classes, onClose, disableClose } = props;
  return (
    <MuiDialogTitle disableTypography >
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close"
        disabled={!!disableClose}
        className={classes.close}
        onClick={onClose}>
        <Close />
      </IconButton>
    </MuiDialogTitle>
  );
});

export default DialogTitleWithClose;