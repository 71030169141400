import React, { useState } from "react";
import styled from "styled-components";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withFirebase, withFirestore } from 'react-redux-firebase';
import {
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography,
  Link,
  Container,
  Grid,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import LogoIcon from "../../components/LogoIcon";
import ButtonProgress from "../../components/ButtonProgress";
import FormError from "../../components/FormError";

const Button = styled(MuiButton)(spacing);
const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

const SignUp = ({ firebase, firestore, history }) => {
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [firstnameError, setFirstnameError] = useState(null);
  const [lastnameError, setLastnameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [password2Error, setPassword2Error] = useState(null);
  const [serverError, setServerError] = useState(null);

  function resetError() {
    setEmailError(null);
    setFirstnameError(null);
    setLastnameError(null);
    setPasswordError(null);
    setPassword2Error(null);
    setServerError(null);
  }

  function handleSubmit(event) {
    event.preventDefault();
    resetError();
    let hasError = false;
    if (email === '') {
      setEmailError('Please input valid email address.');
      hasError = true;
    }
    if (firstname === '') {
      setFirstnameError('Please input valid first name');
      hasError = true;
    }
    if (lastname === '') {
      setLastnameError('Please input valid last name');
      hasError = true;
    }
    if (password === '') {
      setPasswordError('Please input valid password.');
      hasError = true;
    } else if (password.length < 6) {
      setPasswordError('Please provide a password at least 6 characters.');
      hasError = true;
    }
    if (password !== password2) {
      setPasswordError('Your password and confirmation password do not match.');
      hasError = true;
    }
    if (hasError) return;
    setLoading(true);
    firebase.createUser(
      // credentials
      {
        email,
        password
      },
      // profile
      {
        email,
        merchant: true,
        first_name: firstname,
        last_name: lastname
      })
      .then(() => {
        history.push('/');
      })
      .catch(error => {
        setServerError(error.message);
        setLoading(false);
      });
  }

  return (
    <Container>
      <Wrapper>
        <LogoIcon />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Get started
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Let’s create your Reduce account
        </Typography>
        <form onSubmit={handleSubmit} noValidate>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              error={!!emailError}
              disabled={loading}
              onKeyDown={_ => setEmailError(null)}
              onChange={event => setEmail(event.target.value)}
            />
            <FormError error={emailError} />
          </FormControl>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="firstname">First Name</InputLabel>
                <Input id="firstname"
                  name="firstname"
                  value={firstname}
                  error={!!firstnameError}
                  disabled={loading}
                  onKeyDown={_ => setFirstnameError(null)}
                  onChange={event => setFirstname(event.target.value)}
                />
                <FormError error={firstnameError} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="lastname">Last Name</InputLabel>
                <Input id="lastname"
                  name="lastname"
                  value={lastname}
                  error={!!lastnameError}
                  disabled={loading}
                  onKeyDown={_ => setLastnameError(null)}
                  onChange={event => setLastname(event.target.value)}
                />
                <FormError error={lastnameError} />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              value={password}
              error={!!passwordError}
              disabled={loading}
              onKeyDown={_ => setPasswordError(null)}
              onChange={event => setPassword(event.target.value)}
            />
            <FormError error={passwordError} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password2">Confirm Password</InputLabel>
            <Input id="password2"
              name="password2"
              type="password"
              value={password2}
              error={!!password2Error}
              disabled={loading}
              onKeyDown={_ => setPassword2Error(null)}
              onChange={event => setPassword2(event.target.value)}
            />
            <FormError error={password2Error} />
          </FormControl>
          <FormError error={serverError} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            mt={4}
            mb={2}
          >
            <ButtonProgress loading={loading} />
            Sign Up
          </Button>
          <Grid container direction="row-reverse">
            <Grid item >
              <Link component={RouterLink} to="/signin">
                {"Already have an account? Sign in"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Wrapper>
    </Container>
  );
}

export default withRouter(withFirebase(withFirestore(SignUp)));
