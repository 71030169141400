import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase';
import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { AlertCircle } from "react-feather";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitleWithClose from './DialogTitleWithClose';
import ButtonProgress from "../ButtonProgress";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;
const TableCellContent = styled(TableCell)`
  min-width: 200px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MenuList = ({ menus, onMenuSelect }) => {
  return (
    <TableWrapper>
      <Table>
        <TableBody>
          {menus.map(menu => (
            <TableRowClickable hover key={menu.id}
              onClick={_ => { onMenuSelect(menu.id); }}>
              <TableCell padding="checkbox">
                <Checkbox checked={menu.selected} />
              </TableCell>
              <TableCellContent component="th" scope="row">
                {menu.name}
              </TableCellContent>
            </TableRowClickable>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}

const MenuEmpty = () => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
      <Grid item>
        <AlertCircle size={96} color={"#CED4D9"} />
      </Grid>
      <Grid item>
        <Typography variant="h6" align="center" gutterBottom>
          You haven't create any menu yet
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
          Set-up a menu for your restaurant and your customers can start order.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          component={Link}
          color="primary"
          to="/inventory/menus"
          variant="contained">
          Go to Menu Page
        </Button>
      </Grid>
    </Grid>
  );
}

// category add to menu
const AddToMenuDialog = ({ auth, firestore, open, onClose, category, menus }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [selectMenus, setSelectMenus] = useState(initSelectMenus());

  function initSelectMenus() {
    return menus.map(menu => {
      let selected = false;
      for (let i = 0; i < menu.categories.length; i++) {
        if (menu.categories[i].id === category.id) {
          selected = true;
          break;
        }
      }
      return { selected, ...menu };
    });
  }

  function handleMenuSelect(id) {
    const newMenus = selectMenus.map(menu => {
      if (id === menu.id) {
        menu.selected = !menu.selected;
      }
      return { ...menu };
    });
    setSelectMenus(newMenus);
  }

  async function handleSubmit() {

    setLoading(true);

    for (let i = 0; i < selectMenus.length; i++) {
      const selectMenu = selectMenus[i];
      let apply = false;
      const categories = [];
      for (let k = 0; k < selectMenu.categories.length; k++) {
        const menuCategory = selectMenu.categories[k];
        if (category.id === menuCategory.id) {
          apply = true;
          if (!selectMenu.selected)
            continue;
        }
        categories.push({
          id: menuCategory.id,
          name: menuCategory.name,
        });
      }
      if (!apply && selectMenu.selected) {
        // add this category
        categories.push({
          id: category.id,
          name: category.name,
        });
      }
      await firestore.collection('merchants')
        .doc(auth.uid)
        .collection('menus')
        .doc(selectMenu.id)
        .set({
          name: selectMenu.name,
          categories,
        });
    }

    setLoading(false);
    onClose();
  }

  function handleClose() {
    setSelectMenus(initSelectMenus());
    onClose();
  }

  const hasMenu = selectMenus.length > 0;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="category-add-to-menu-dialog-title">
      <DialogTitleWithClose id="category-add-to-menu-dialog-title"
        disableClose={loading}
        onClose={handleClose}>
        {`Add Category ${category ? category.name : ''} to Menu`}
      </DialogTitleWithClose>
      <DialogContent dividers={hasMenu}>
        {
          hasMenu ?
            <MenuList
              menus={selectMenus}
              onMenuSelect={handleMenuSelect} /> :
            <MenuEmpty />
        }
      </DialogContent>
      <DialogActions>
        {hasMenu &&
          <Button
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={handleSubmit}>
            <ButtonProgress loading={loading} />
            Save Change
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(withFirestore(AddToMenuDialog));