export const MODIFIER_TYPE_RADIO = 'Radio';
export const MODIFIER_TYPE_CHECKBOX = 'Checkbox';

export const TENDER_TYPE_CASH = 'Cash';
export const TENDER_TYPE_CARD = 'Card';

export const STATUS_PENDING = 'pending';
export const STATUS_READY = 'ready';
export const STATUS_DONE = 'done';

export const PAYMENT_SQUARE = 'Square';

export const DATE_FILTER_TODAY = 'today';
export const DATE_FILTER_YESTERDAY = 'yesterday';
export const DATE_FILTER_THIS_WEEK = 'thisWeek';
export const DATE_FILTER_LAST_WEEK = 'lastWeek';
export const DATE_FILTER_THIS_MONTH = 'thisMonth';
export const DATE_FILTER_LAST_MONTH = 'lastMonth';
export const DATE_FILTER_ALL = 'all';

export const DISCOUNT_TYPE_PERCENTAGE_OFF = "PercentageOff";
export const DISCOUNT_TYPE_AMOUNT_OFF = "AmountOff";
export const DISCOUNT_TYPE_BUY_ONE_GET_ONE_FREE = "BuyOneGetOneFree";

export const COUPON_USE_TYPE_ONETIME = 'OneTimeUse';
export const COUPON_USE_TYPE_EXPIRE = 'Expiration';
export const COUPON_TYPE_PERMANENT = "Permanent";

export const COLOR_GREY = "#CED4D9";
export const COLOR_GREEN = "#4CAF50";