import React from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  Grid,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { AlertCircle } from "react-feather";
import { spacing } from "@material-ui/system";
import Loader from "../../components/Loader";
import ModifierDialog from "../../components/dialogs/ModifierDialog";
import ApplyToItemDialog from "../../components/dialogs/ApplyToItemDialog";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;
const TableCellContent = styled(TableCell)`
  min-width: 200px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TableCellButton = styled(TableCell)`
  min-width: 180px;
`;
const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

class ModifierList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      openApplyToItemDialog: false,
      selectedModifier: null,
    }
  }

  handleOpenDialog = (modifier) => {
    this.setState({ openDialog: true, selectedModifier: modifier });
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleOpenApplyToItemDialog = (modifier) => {
    this.setState({ openApplyToItemDialog: true, selectedModifier: modifier });
  }

  handleCloseApplyToItemDialog = () => {
    this.setState({ openApplyToItemDialog: false });
  }

  renderList = (modifiers) => {

    return (
      <React.Fragment>
        <CardContent mb={2}>
          <Grid container direction="column" alignItems="flex-end" spacing={3}>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                onClick={_ => this.handleOpenDialog(null)}>
                Create New Modifier
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Options</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modifiers.map(modifier => (
                  <TableRowClickable hover key={modifier.id}>
                    <TableCellContent component="th" scope="row"
                      onClick={_ => this.handleOpenDialog(modifier)}>
                      {modifier.name}
                    </TableCellContent>
                    <TableCellContent
                      onClick={_ => this.handleOpenDialog(modifier)}>
                      {modifier.title}
                    </TableCellContent>
                    <TableCellContent
                      onClick={_ => this.handleOpenDialog(modifier)}>
                      {modifier.options.map(o => o.title).join(', ')}
                    </TableCellContent>
                    <TableCellButton align="right">
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={_ => this.handleOpenApplyToItemDialog(modifier)}>
                        Apply to Item
                      </Button>
                    </TableCellButton>
                  </TableRowClickable>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </Paper>
      </React.Fragment>
    );
  }

  renderEmpty = () => {
    return (
      <CardContent mt={6} mb={6}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item>
            <AlertCircle size={96} color={"#CED4D9"} />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" gutterBottom>
              You haven't create any modifier yet
              </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
              Create a modifier that can be applied to your menu item for customers to customize their orders.
              </Typography>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mt={3}
              onClick={_ => this.handleOpenDialog(null)}>
              Create a Modifier
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    );
  }

  mutableModifier = () => {
    const { selectedModifier } = this.state;
    if (!selectedModifier) return null;
    const mutable = { ...selectedModifier };
    mutable.options = [];
    selectedModifier.options.map(
      option => mutable.options.push({ ...option })
    );
    return mutable;
  }

  render() {

    const { modifiers, items } = this.props;
    const { openDialog, openApplyToItemDialog } = this.state;

    const selectedModifier = this.mutableModifier();

    return (
      <Card mb={6}>
        {
          modifiers.length > 0 ?
            this.renderList(modifiers) :
            this.renderEmpty()
        }
        <ModifierDialog
          open={openDialog}
          modifier={selectedModifier}
          items={items}
          onClose={this.handleCloseDialog} />
        {openApplyToItemDialog && <ApplyToItemDialog
          open={openApplyToItemDialog}
          modifier={selectedModifier}
          items={items}
          onClose={this.handleCloseApplyToItemDialog}
        />}
      </Card>
    );
  }
}

function Modifiers({ modifiers, items }) {

  if (modifiers === null)
    return <Loader />;

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom display="inline">
        Modifiers
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Setting</Typography>
        <Typography>Menu</Typography>
        <Typography>Modifiers</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ModifierList modifiers={modifiers} items={items} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const modifiers = state.firestore.ordered.modifiers;
  const items = state.firestore.ordered.items;
  if (modifiers) {
    return {
      auth: state.firebase.auth,
      modifiers,
      items: items || [],
    }
  }
  return {
    auth: state.firebase.auth,
    modifiers: null,
    items: null,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    const { auth } = props;
    return [
      {
        collection: 'merchants',
        doc: auth.uid,
        subcollections: [{
          collection: 'items',
        }],
        storeAs: 'items',
      },
      {
        collection: 'merchants',
        doc: auth.uid,
        subcollections: [{
          collection: 'modifiers',
        }],
        storeAs: 'modifiers',
      },
    ]
  })
)(Modifiers);