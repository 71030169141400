import React from 'react';
import { Dialog, DialogActions, Button, DialogContent } from '@material-ui/core';
import DialogTitleWithClose from './DialogTitleWithClose';
import DangerButton from '../buttons/DangerButton';

const ConfirmDialog = ({ open, onClose, onConfirm, title, children, danger }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirm-dialog-title">
      <DialogTitleWithClose id="confirm-dialog-title"
        onClose={onClose}>
        {title}
      </DialogTitleWithClose>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined"
          color="primary"
          onClick={onClose}>
          Cancel
        </Button>
        {
          danger ?
            <DangerButton variant="contained"
              onClick={onConfirm}>
              Confirm
            </DangerButton> :
            <Button variant="contained"
              color="primary"
              onClick={onConfirm}>
              Confirm
            </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;