import React, { useState } from "react";
import styled from "styled-components";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withFirebase } from 'react-redux-firebase';
import {
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography,
  Link,
  Grid,
  Container,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import LogoIcon from "../../components/LogoIcon";
import ButtonProgress from "../../components/ButtonProgress";
import FormError from "../../components/FormError";

const Button = styled(MuiButton)(spacing);
const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

const SignIn = ({ firebase, history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [serverError, setServerError] = useState(null);

  function resetError() {
    setEmailError(null);
    setPasswordError(null);
    setServerError(null);
  }

  function handleSubmit(event) {
    event.preventDefault();
    resetError();
    let hasError = false;
    if (!email) {
      setEmailError('Please input valid email address.');
      hasError = true;
    }
    if (!password) {
      setPasswordError('Please input valid password.');
      hasError = true;
    }
    if (hasError) return;
    setLoading(true);
    firebase.login({
      email,
      password
    }).then(() => {
      setTimeout(() => {
        // give 1 sec for load
        history.push('/');
      }, 1000);
    }).catch((error) => {
      setServerError(error.message);
      setLoading(false);
    });
  }

  return (
    <Container>
      <Wrapper>
        <LogoIcon />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome back
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>
        <form onSubmit={handleSubmit} noValidate>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              error={!!emailError}
              disabled={loading}
              onKeyDown={_ => setEmailError(null)}
              onChange={event => setEmail(event.target.value)}
            />
            <FormError error={emailError} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              value={password}
              error={!!passwordError}
              disabled={loading}
              onKeyDown={_ => setPasswordError(null)}
              onChange={event => setPassword(event.target.value)}
            />
            <FormError error={passwordError} />
          </FormControl>
          <FormError error={serverError} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            mt={4}
            mb={2}>
            <ButtonProgress loading={loading} />
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/reset-password">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/signup">
                {"Don't have an account? Sign up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Wrapper>
    </Container>
  );
}

export default withRouter(withFirebase(SignIn));
