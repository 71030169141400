import React from "react";

const LogoIcon = () => {
  return (
    <img src="/static/img/logo.png"
      width={64} height={64}
      style={{
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 16,
      }}
      alt="Reduce" />
  );
}

export default LogoIcon;