import React, { Component } from "react";
import styled, { withTheme } from "styled-components";

import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Line } from "react-chartjs-2";
import { round } from "../../utils";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

class DailyProfit extends Component {
  constructor() {
    super();

    this.data = (canvas) => {
      const ctx = canvas.getContext("2d");

      const gradient = ctx.createLinearGradient(0, 0, 0, 300);
      gradient.addColorStop(
        0,
        fade(this.props.theme.palette.secondary.main, 0.0875)
      );
      gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

      const labels = [];
      const card_data = [];
      const cash_data = [];
      for (let i = this.props.salesDaily.length - 1; i >= 0; i--) {
        const sales_data = this.props.salesDaily[i];
        const date_parts = sales_data.date.split("-")
        labels.push(date_parts[0] + "/" + date_parts[1]);
        card_data.push(round(sales_data.total_card - sales_data.refund_card));
        cash_data.push(round(sales_data.total_cash - sales_data.refund_cash));
      }

      return {
        labels: labels,
        datasets: [
          {
            label: "Card ($)",
            fill: true,
            backgroundColor: gradient,
            borderColor: this.props.theme.palette.secondary.main,
            data: card_data,
          },
          {
            label: "Cash ($)",
            fill: true,
            backgroundColor: "transparent",
            borderColor: this.props.theme.palette.grey["500"],
            data: cash_data,
          },
        ],
      };
    };

    this.options = {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      tooltips: {
        intersect: false,
      },
      hover: {
        intersect: true,
      },
      plugins: {
        filler: {
          propagate: false,
        },
      },
      scales: {
        xAxes: [
          {
            reverse: true,
            gridLines: {
              color: "rgba(0,0,0,0.0)",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              stepSize: 500,
            },
            display: true,
            borderDash: [5, 5],
            gridLines: {
              color: "rgba(0,0,0,0.0375)",
              fontColor: "#fff",
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <Card mb={3}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Daily Profit
          </Typography>

          <Spacer mb={6} />

          <ChartWrapper>
            <Line data={this.data} options={this.options} />
          </ChartWrapper>
        </CardContent>
      </Card>
    );
  }
}

export default withTheme(DailyProfit);
