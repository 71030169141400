import * as types from '../constants';
import {
  DATE_FILTER_TODAY,
  DATE_FILTER_YESTERDAY,
  DATE_FILTER_THIS_WEEK,
  DATE_FILTER_LAST_WEEK,
  DATE_FILTER_THIS_MONTH,
  DATE_FILTER_LAST_MONTH,
  DATE_FILTER_ALL,
} from '../../constants';

export function setDateToday() {
  return {
    type: types.SET_DATE,
    payload: DATE_FILTER_TODAY,
  }
}

export function setDateYesterday() {
  return {
    type: types.SET_DATE,
    payload: DATE_FILTER_YESTERDAY,
  }
}

export function setDateThisWeek() {
  return {
    type: types.SET_DATE,
    payload: DATE_FILTER_THIS_WEEK,
  }
}

export function setDateLastWeek() {
  return {
    type: types.SET_DATE,
    payload: DATE_FILTER_LAST_WEEK,
  }
}

export function setDateThisMonth() {
  return {
    type: types.SET_DATE,
    payload: DATE_FILTER_THIS_MONTH,
  }
}

export function setDateLastMonth() {
  return {
    type: types.SET_DATE,
    payload: DATE_FILTER_LAST_MONTH,
  }
}

export function setDateAll() {
  return {
    type: types.SET_DATE,
    payload: DATE_FILTER_ALL,
  }
}