import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery, Dialog, Grid, Slider, DialogContent, DialogActions, Button } from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';
import DialogTitleWithClose from './DialogTitleWithClose';

const useStyles = makeStyles(theme => ({
  root: {
    width: 300 + 24 * 2,
    padding: 0,
  },
}));

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const marks = [
  // {
  //   value: 0,
  // },
  // {
  //   value: 100,
  // },
];

const IOSSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus,&:hover,&$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    backgroundColor: 'currentColor',
  },
})(Slider);

const ImageEditDialog = ({ id, open, onEdit, onClose, imageFile }) => {

  const [slideValue, setSlideValue] = useState(50);

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let imageEditor;

  function getScale() {
    return 0.5 + (slideValue / 100);
  }

  function handleSliderChange(_, value) {
    setSlideValue(value);
  };

  function handleConfirmClick() {
    // onEdit(id, imageEditor.getImage().toDataURL());
    onEdit(id, imageEditor.getImageScaledToCanvas().toDataURL());
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="image-edit-dialog-title">
      <DialogTitleWithClose id="image-edit-dialog-title"
        onClose={onClose}>
        Modifier Option Image
      </DialogTitleWithClose>
      <DialogContent dividers>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item>
            {
              imageFile ? // https://github.com/mosch/react-avatar-editor/issues/322
                <AvatarEditor
                  ref={editor => imageEditor = editor}
                  image={imageFile}
                  width={360}
                  height={360}
                  border={10}
                  color={[0, 0, 80, 0.1]} // RGBA
                  scale={getScale()}
                  rotate={0}
                /> : null
            }
          </Grid>
          <Grid item>
            <div className={classes.root}>
              <IOSSlider
                aria-label="ios slider"
                value={slideValue}
                marks={marks}
                valueLabelDisplay="off"
                onChange={handleSliderChange} />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleConfirmClick}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImageEditDialog;