import React from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  Grid,
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Chip as MuiChip,
  Tooltip,
} from "@material-ui/core";
import { AlertCircle, CreditCard, DollarSign, Square, CheckSquare } from "react-feather";
import { spacing } from "@material-ui/system";
import withWidth from "@material-ui/core/withWidth";
import MaterialTable from 'material-table';
import { CSVLink as ReactCSVLink } from "react-csv";
import Loader from "../../components/Loader";
import { green, orange, blue, red, grey } from "@material-ui/core/colors";
import {
  STATUS_PENDING,
  STATUS_READY,
  TENDER_TYPE_CASH,
  PAYMENT_SQUARE,
  DATE_FILTER_TODAY,
  DATE_FILTER_YESTERDAY,
  DATE_FILTER_THIS_WEEK,
  DATE_FILTER_LAST_WEEK,
  TENDER_TYPE_CARD
} from "../../constants";
import {
  getOrderDescription,
  capitalizeString,
  formatDateFull,
  formatDate,
  formatTime,
  sfTodayDateString,
  sfYesterdayDateString,
  sfWeekFirstDateString,
  sfLastWeekFirstDateString,
  getTableOffsetWidth,
} from "../../utils";
import DateRange from "../components/DateRange";
import { setLocationWithDispatch } from "../../redux/actions/locationActions";

const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${props => props.rgbcolor};
  color: ${props => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.offset}px - ${props => props.theme.spacing(16)}px);
`;
const CSVLink = styled(ReactCSVLink)`
  color: white;
  text-decoration: none;
`;

const CSV_HEADERS = [
  { label: "Date", key: "date" },
  { label: "Time", key: "time" },
  { label: "Order Number", key: "order_number" },
  { label: "Description", key: "description" },
  { label: "Status", key: "order_status" },
  { label: "Order At", key: "order_at" },
  { label: "Dining Option", key: "dining_option" },
  { label: "Tender Type", key: "tender_type" },
  { label: "Payment Gateway", key: "payment_provider" },
  { label: "Cash Collected", key: "cash_collected" },
  { label: "Subtotal", key: "subtotal" },
  { label: "Tax", key: "tax" },
  { label: "Tip", key: "tip" },
  { label: "Discount", key: "discount" },
  { label: "Total", key: "total" },
  { label: "Note", key: "note" },
  { label: "From Cashier", key: "from_cashier" },
];

const OrdersEmpty = () => {
  return (
    <Card mb={6}>
      <CardContent mt={6} mb={6}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item>
            <AlertCircle size={96} color={"#CED4D9"} />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" gutterBottom>
              There is no order yet.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
              Orders will be list here when new order generated.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const OrdersTable = ({ records, offset }) => {
  return (
    <TableWrapper offset={offset}>
      <MaterialTable
        columns={[
          {
            title: 'Time', field: 'datetime', type: 'datetime', render: record => {
              return <Typography variant="caption">{record.datetime}</Typography>;
            }
          },
          { title: 'Order Number', field: 'order_number' },
          {
            title: 'Description', field: 'description', render: record => {
              if (record.description.length > 20) {
                return (
                  <Tooltip title={record.description}>
                    <div style={{ cursor: 'pointer' }}>
                      {record.description.substring(0, 17) + '...'}
                    </div>
                  </Tooltip>
                );
              }
              return record.description;
            }
          },
          {
            title: 'Status', field: 'status', render: record => {
            let color = green[500];
              if (record.status === 'refunded')
                color = red[500];
              else if (record.status === STATUS_PENDING)
                color = orange[500];
              else if (record.status === STATUS_READY)
                color = blue[500];
              return <Chip label={capitalizeString(record.status)} rgbcolor={color} />;
            }
          },
          { title: 'Order At', field: 'order_at' },
          { title: 'Dining Option', field: 'dining_option' },
          {
            title: 'Tender Type', field: 'tender_type', render: record => {
              if (record.tender_type === TENDER_TYPE_CASH) {
                // return <AlertCircle size={96} color={"#CED4D9"} />;
                return <DollarSign color={grey[600]} />;
              } else if (record.tender_type === TENDER_TYPE_CARD) {
                return <CreditCard color={grey[600]} />;
              }
              return <Chip label='N/A' rgbcolor={grey[500]} />;
            }
          },
          {
            title: 'Payment Gateway', field: 'payment_provider', render: record => {
              if (record.payment_provider === PAYMENT_SQUARE) {
                return <Chip label='Square' rgbcolor={grey[800]} />;
              }
              return <Chip label='N/A' rgbcolor={grey[500]} />;
            }
          },
          {
            title: 'Cash Collected', field: 'cash_collected', render: record => {
              if (record.cash_collected === "N/A") {
                return <Chip label='N/A' rgbcolor={grey[500]} />;
              }
              if (!record.cash_collected) {
                return <Chip label='Not Collected' rgbcolor={red[500]} />;
              }
              return <Chip label='Collected' rgbcolor={green[500]} />;
            },
          },
          { title: 'Subtotal', field: 'subtotal', type: 'numeric' },
          { title: 'Tax', field: 'tax', type: 'numeric' },
          { title: 'Tip', field: 'tip', type: 'numeric' },
          { title: 'Discount', field: 'discount', type: 'numeric' },
          { title: 'Total', field: 'total', type: 'numeric' },
          { title: 'Note', field: 'note' },
          {
            title: 'From Cashier', field: 'from_cashier', type: 'boolean', render: record => {
              if (record.from_cashier) {
                return <CheckSquare color={grey[600]} />;
              }
              return <Square color={grey[600]} />;
            }
          },
        ]}
        data={records}
        options={{
          // toolbar: false,
          showTitle: false,
          // exportButton: true,
          // exportFileName: 'report',
          // exportAllData: true,
          // search: false,
          pageSize: 5,
          pageSizeOptions: [5, 25, 50, 100],
          sorting: false,
        }}
      />
    </TableWrapper>
  );
}

const Orders = ({ orders, width }) => {

  if (orders === null)
    return <Loader />;

  // prepare export csv file
  const records = [];
  orders.forEach(order => {
    const {
      created_at,
      order_number,
      status,
      dining_option,
      tender_type,
      payment_provider,
      subtotal,
      tax,
      tip,
      discount,
      total,
      refund_amount,
      from_cashier,
      order_at,
      note,
      require_collect_cash,
    } = order;
    const created_at_date = created_at.toDate();
    const date = formatDate(created_at_date);
    const time = formatTime(created_at_date);
    const datetime = formatDateFull(created_at_date);
    const description = getOrderDescription(order);
    let order_status = status;
    if (refund_amount > 0) {
      order_status = "refunded";
    }
    let cash_collected = "N/A";
    if (order.tender_type === TENDER_TYPE_CASH) {
      if (require_collect_cash) {
        cash_collected = false;
      } else {
        cash_collected = true;
      }
    }
    records.push({
      date,
      time,
      datetime,
      order_number,
      description,
      status: order_status,
      order_status: capitalizeString(order_status),
      dining_option: capitalizeString(dining_option),
      tender_type,
      payment_provider,
      cash_collected,
      subtotal: `$${subtotal}`,
      tax: `$${tax}`,
      tip: `$${tip}`,
      discount: `$${discount}`,
      total: `$${total}`,
      note: !!note ? note : '',
      from_cashier,
      order_at,
    });
  });

  return (
    <React.Fragment>
      <Grid justify="space-between" container alignItems="center" spacing={6}>
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
            Orders
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Report</Typography>
            <Typography>Orders</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <DateRange />

          <CSVLink data={records} headers={CSV_HEADERS} filename={"report.csv"} target="_blank">
            <Button
              variant="contained"
              size="small"
              color="secondary">
              Export .csv file
            </Button>
          </CSVLink>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {
            records.length === 0 ? <OrdersEmpty /> :
              <OrdersTable
                records={records}
                offset={getTableOffsetWidth(width)} />
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const locations = state.firestore.ordered.locations;
  const merchant = state.firestore.data.merchant || null;
  if (!!locations) {
    const locationId = state.location.currentLocation || locations[0]['id'];
    if (!state.location.currentLocation) {
      setLocationWithDispatch(locations[0]['id']);
    }
    const location = locations.find(loc => loc['id'] === locationId) || locations[0];
    const orders = state.firestore.ordered.orders || [];
    const ordersRequested = state.firestore.status.requested.orders || false;
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      merchant: merchant,
      currentDate: state.date.currentDate,
      location,
      orders: ordersRequested ? orders : null,
    }
  }
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    merchant: merchant,
    currentDate: state.date.currentDate,
    location: null,
    orders: null,
  }
}

export default compose(
  withWidth(),
  connect(mapStateToProps),
  firestoreConnect(props => {
    const { auth, location, merchant, currentDate } = props;
    const query = [];
    if (!merchant) {
      query.push({
        collection: 'merchants',
        doc: auth.uid,
        storeAs: 'merchant',
      });
    }
    if (location) {
      const whereClause = [];
      if (currentDate === DATE_FILTER_TODAY) {
        whereClause.push(
          ['created_at', '>=', new Date(sfTodayDateString())]
        );
      }
      if (currentDate === DATE_FILTER_YESTERDAY) {
        whereClause.push(
          ['created_at', '<=', new Date(sfTodayDateString())]
        );
        whereClause.push(
          ['created_at', '>=', new Date(sfYesterdayDateString())]
        );
      }
      if (currentDate === DATE_FILTER_THIS_WEEK) {
        whereClause.push(
          ['created_at', '<=', new Date(sfTodayDateString())]
        );
        whereClause.push(
          ['created_at', '>=', new Date(sfWeekFirstDateString())]
        );
      }
      if (currentDate === DATE_FILTER_LAST_WEEK) {
        whereClause.push(
          ['created_at', '<', new Date(sfWeekFirstDateString())]
        );
        whereClause.push(
          ['created_at', '>=', new Date(sfLastWeekFirstDateString())]
        );
      }
      query.push({
        // collection: `locations/${location.id}/orders`,
        collection: 'locations',
        doc: location.id,
        subcollections: [{
          collection: 'orders',
        }],
        orderBy: [['created_at', 'desc']],
        where: whereClause,
        // limit: 25,
        storeAs: 'orders',
      });
    } else {
      query.push({
        collection: 'locations',
        where: [
          ['merchant_id', '==', auth.uid]
        ],
        storeAs: 'locations',
      });
    }
    return query;
  })
)(Orders);