import React from 'react';
import { PhotoCamera } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

const ImageFileInput = ({ id, thumbnail, onChange, disabled, iconSize, thumbnailSize }) => {
  const fileInput = React.createRef();

  function handleChange(event) {
    event.preventDefault();
    const file = fileInput.current.files[0];
    // TODO, verify other type: http://usunyu.com/note/2019/08/25/file-ext-for-content-type/
    // TODO, error message for not support
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      onChange(id, file);
    }
  }

  return (
    <React.Fragment>
      <input id={`imageFileInput${id}`}
        accept="image/*"
        style={{ display: "none" }}
        type="file"
        ref={fileInput}
        disabled={disabled}
        onChange={handleChange} />
      <label htmlFor={`imageFileInput${id}`}>
        {
          thumbnail ?
            <div className="imghover-container"
              style={{ cursor: "pointer" }}>
              <img src={thumbnail}
                className="imghover-image"
                width={thumbnailSize}
                height={thumbnailSize}
                style={{ borderRadius: "6px" }}
                alt="Thumbnail" />
            </div> :
            <IconButton
              aria-label="Upload Image"
              component="span"
              style={{ textAlign: "center" }}>
              <PhotoCamera style={{ fontSize: iconSize }} />
            </IconButton>
        }
      </label>
    </React.Fragment>
  );
}

export default ImageFileInput;