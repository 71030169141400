import React, { Component } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter, Redirect } from "react-router-dom";
import { withFirestore } from 'react-redux-firebase';
import qs from 'query-string';
import {
  Grid,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";
import { CheckCircle } from "react-feather";
import { spacing } from "@material-ui/system";
import Loader from "../../components/Loader";
import { COLOR_GREEN } from "../../constants";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);

const prodAppId = 'sq0idp-V6FhqzxRKsoEnJpi34evhA';
const devAppId = 'sq0idp-BR7e_ZAkkmwUCvxtrz4Hvw';
const appId = process.env.NODE_ENV === "production" ? prodAppId : devAppId;
const oauthUrl = `https://squareup.com/oauth2/authorize?client_id=${appId}&scope=EMPLOYEES_READ EMPLOYEES_WRITE ITEMS_READ ITEMS_WRITE MERCHANT_PROFILE_READ PAYMENTS_READ PAYMENTS_WRITE PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS PAYMENTS_WRITE_IN_PERSON TIMECARDS_READ TIMECARDS_WRITE CUSTOMERS_READ CUSTOMERS_WRITE`;

const SquareIntegrate = () => {
  return (
    <Card mb={6}>
      <CardContent mt={6} mb={6}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item>
            <img src="/static/img/integration/square.png"
              width={240}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 16,
              }}
              alt="Square Integration" />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" gutterBottom>
              Integrate with Square
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
              You can integrate with your Square account to enable more features.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              href={oauthUrl}
              variant="contained"
              color="primary"
              mt={3}>
              Integrate Now
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const SquareIntegrated = () => {
  return (
    <Card mb={6}>
      <CardContent mt={6} mb={6}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item>
            <img src="/static/img/integration/square.png"
              width={240}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 16,
              }}
              alt="Square Integration" />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">
              You have already integrated with Square
            </Typography>
          </Grid>
          <Grid item>
            <CheckCircle size={36} color={COLOR_GREEN} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}


class SquareIntegration extends Component {

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
    };
  }

  componentDidMount() {
    const { auth, firestore, location } = this.props;
    const parsed = qs.parse(location.search);
    if (
      parsed.access_token &&
      parsed.expires_at &&
      parsed.refresh_token &&
      parsed.merchant_id &&
      parsed.token_type
    ) {
      this.setState({ updating: true });
      const square_account = {
        'access_token': parsed.access_token,
        'expires_at': parsed.expires_at,
        'merchant_id': parsed.merchant_id,
        'refresh_token': parsed.refresh_token,
        'token_type': parsed.token_type,
      }
      // save to firebase
      firestore.collection('merchants')
        .doc(auth.uid)
        .update({ square_account })
        .then(() => {
          this.setState({ updating: false });
        })
        .catch((error) => {
          this.setState({ updating: false });
          return <Redirect to="/error" />;
        });
    }
  }

  render() {
    const { updating } = this.state;
    const { merchant } = this.props;

    if (merchant === null || updating)
      return <Loader />;

    const square_account = merchant.square_account || null;
    // check if have valid square integration
    let integrated = square_account != null;
    if (square_account) {
      const now = new Date();
      const expires_at = new Date(square_account.expires_at);
      if (expires_at.getTime() < now.getTime()) {
        integrated = false;
      }
    }

    return (
      <React.Fragment>
        <Grid justify="space-between" container alignItems="center" spacing={6}>
          <Grid item>
            <Typography variant="h4" gutterBottom display="inline">
              Integrate with Square
          </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Typography>Setting</Typography>
              <Typography>Integration</Typography>
              <Typography>Square</Typography>
            </Breadcrumbs>
          </Grid>

          {/* <Grid item>
        </Grid> */}
        </Grid>

        <Divider my={6} />

        <Grid container spacing={6}>
          <Grid item xs={12}>
            {
              integrated ?
                <SquareIntegrated /> :
                <SquareIntegrate />
            }
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const merchant = state.firestore.data.merchant;
  if (merchant) {
    return {
      auth: state.firebase.auth,
      merchant,
    }
  }
  return {
    auth: state.firebase.auth,
    merchant: null,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    const { auth } = props;
    return [
      {
        collection: `merchants`,
        doc: auth.uid,
        storeAs: 'merchant',
      },
    ]
  })
)(withFirestore(withRouter(SquareIntegration)));