import * as types from '../constants';
import { DATE_FILTER_TODAY } from '../../constants';

export default function reducer(state = { currentDate: DATE_FILTER_TODAY }, actions) {
  switch (actions.type) {

    case types.SET_DATE:
      return {
        ...state,
        currentDate: actions.payload
      }

    default:
      return state
  }
}
