import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase';
import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { AlertCircle } from "react-feather";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitleWithClose from './DialogTitleWithClose';
import ButtonProgress from "../ButtonProgress";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;
const TableCellContent = styled(TableCell)`
  min-width: 200px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CategoryList = ({ categories, onCategorySelect }) => {
  return (
    <TableWrapper>
      <Table>
        <TableBody>
          {categories.map(category => (
            <TableRowClickable hover key={category.id}
              onClick={_ => { onCategorySelect(category.id); }}>
              <TableCell padding="checkbox">
                <Checkbox checked={category.selected} />
              </TableCell>
              <TableCellContent component="th" scope="row">
                {category.name}
              </TableCellContent>
            </TableRowClickable>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}

const CategoryEmpty = () => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
      <Grid item>
        <AlertCircle size={96} color={"#CED4D9"} />
      </Grid>
      <Grid item>
        <Typography variant="h6" align="center" gutterBottom>
          You haven't create any category yet
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
          Categories make it easy for your customers to find inventory items.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          component={Link}
          color="primary"
          to="/inventory/categories"
          variant="contained">
          Go to Category Page
        </Button>
      </Grid>
    </Grid>
  );
}

// item apply to category
const AddToCategoryDialog = ({ auth, firestore, open, onClose, item, categories }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [selectCategories, setSelectCategories] = useState(initSelectCategories());

  function initSelectCategories() {
    return categories.map(category => {
      let selected = false;
      for (let i = 0; i < category.items.length; i++) {
        if (category.items[i].id === item.id) {
          selected = true;
          break;
        }
      }
      return { selected, ...category };
    });
  }

  function handleCategorySelect(id) {
    const newCategories = selectCategories.map(category => {
      if (id === category.id) {
        category.selected = !category.selected;
      }
      return { ...category };
    });
    setSelectCategories(newCategories);
  }

  async function handleSubmit() {

    setLoading(true);

    for (let i = 0; i < selectCategories.length; i++) {
      const selectCategory = selectCategories[i];
      let apply = false;
      const items = [];
      for (let k = 0; k < selectCategory.items.length; k++) {
        const categoryItem = selectCategory.items[k];
        if (item.id === categoryItem.id) {
          apply = true;
          if (!selectCategory.selected)
            continue;
        }
        items.push({
          id: categoryItem.id,
          name: categoryItem.name,
        });
      }
      if (!apply && selectCategory.selected) {
        // add this item
        items.push({
          id: item.id,
          name: item.name,
        });
      }
      await firestore.collection('merchants')
        .doc(auth.uid)
        .collection('categories')
        .doc(selectCategory.id)
        .set({
          name: selectCategory.name,
          title: selectCategory.title,
          image: selectCategory.image,
          items,
        });
    }

    setLoading(false);
    onClose();
  }

  function handleClose() {
    setSelectCategories(initSelectCategories());
    onClose();
  }

  const hasCategory = selectCategories.length > 0;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="item-add-to-category-dialog-title">
      <DialogTitleWithClose id="item-add-to-category-dialog-title"
        disableClose={loading}
        onClose={handleClose}>
        {`Add Item ${item ? item.name : ''} to Category`}
      </DialogTitleWithClose>
      <DialogContent dividers={hasCategory}>
        {
          hasCategory ?
            <CategoryList
              categories={selectCategories}
              onCategorySelect={handleCategorySelect} /> :
            <CategoryEmpty />
        }
      </DialogContent>
      <DialogActions>
        {hasCategory &&
          <Button
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={handleSubmit}>
            <ButtonProgress loading={loading} />
            Save Change
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(withFirestore(AddToCategoryDialog));