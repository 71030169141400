import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import styled, { withTheme } from "styled-components";
import moment from "moment";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import Stats from "../../components/charts/Stats";
import DailyProfit from "../../components/charts/DailyProfit";

import {
  CreditCard,
  DollarSign,
  TrendingUp,
  ShoppingCart,
} from "react-feather";
import Loader from "../../components/Loader";
import { round } from "../../utils";
import { setLocationWithDispatch } from "../../redux/actions/locationActions";
import LocationSelector from "../components/LocationSelector";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Overview({ profile, theme, salesToday, ordersToday, salesDaily }) {
  if (salesToday === null || ordersToday === null || salesDaily == null) return <Loader />;

  const { total_card, total_cash, refund_card, refund_cash } = salesToday;
  const { in_store_orders, mobile_orders } = ordersToday;

  return (
    <React.Fragment>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Typography variant="h3" display="inline" mr={2}>
                Welcome back
                {profile.first_name ? `, ${profile.first_name}` : ""}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" display="inline">
                {moment().format("dddd, D MMM YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <LocationSelector />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Sales"
            amount={`$ ${round(
              total_card + total_cash - refund_card - refund_cash
            )}`}
            background={theme.palette.secondary.main}
            contrastText={theme.palette.secondary.contrastText}
            icon={TrendingUp}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Collected Card"
            amount={`$ ${round(total_card - refund_card)}`}
            icon={CreditCard}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Collected Cash"
            amount={`$ ${round(total_cash - refund_cash)}`}
            icon={DollarSign}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Orders"
            amount={in_store_orders + mobile_orders}
            icon={ShoppingCart}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <DailyProfit salesDaily={salesDaily} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const INIT_SALE = {
  refund_card: 0.0,
  refund_cash: 0.0,
  tax_card: 0.0,
  tax_cash: 0.0,
  tip_card: 0.0,
  total_card: 0.0,
  total_cash: 0.0,
};

const INIT_ORDER = {
  in_store_orders: 0,
  mobile_orders: 0,
};

const mapStateToProps = (state) => {
  const locations = state.firestore.ordered.locations;
  const merchant = state.firestore.data.merchant || null;

  if (!!locations) {
    const locationId = state.location.currentLocation || locations[0]["id"];
    if (!state.location.currentLocation) {
      setLocationWithDispatch(locations[0]["id"]);
    }
    const location =
      locations.find((loc) => loc["id"] === locationId) || locations[0];
    const sales = state.firestore.ordered.salesToday;
    const salesToday = sales ? (sales.length > 0 ? sales[0] : INIT_SALE) : null;
    const orders = state.firestore.ordered.ordersToday;
    const ordersToday = orders
      ? orders.length > 0
        ? orders[0]
        : INIT_ORDER
      : null;
    const salesDaily = state.firestore.ordered.salesDaily;
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      merchant: merchant,
      location,
      salesToday,
      ordersToday,
      salesDaily,
    };
  }
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    merchant: merchant,
    location: null,
    salesToday: null,
    ordersToday: null,
    salesDaily: null,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const { auth, location, merchant } = props;
    const query = [];
    if (!merchant) {
      query.push({
        collection: "merchants",
        doc: auth.uid,
        storeAs: "merchant",
      });
    }
    query.push({
      collection: "locations",
      where: [["merchant_id", "==", auth.uid]],
      storeAs: "locations",
    });
    if (location) {
      const date = moment().format("MM-DD-YYYY");
      query.push({
        collection: `locations/${location.id}/sales-daily`,
        doc: date,
        storeAs: "salesToday",
      });
      query.push({
        collection: `locations/${location.id}/orders-daily`,
        doc: date,
        storeAs: "ordersToday",
      });
      query.push({
        collection: `locations/${location.id}/sales-daily`,
        limit: 14,
        orderBy: [['updated_at', 'desc']],
        storeAs: "salesDaily",
      });
    }
    return query;
  })
)(withTheme(Overview));
