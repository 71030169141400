import React, { useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { withFirebase } from 'react-redux-firebase';

import {
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import ButtonProgress from "../../components/ButtonProgress";
import FormError from "../../components/FormError";
import InfoSnackbar from "../../components/snackbars/InfoSnackbar";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;
  width: 100%;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

function ResetPassword({ firebase, history }) {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [serverError, setServerError] = useState(null);
  const [showInfoSnackbar, setShowInfoSnackbar] = useState(false);

  function resetError() {
    setEmailError(null);
    setServerError(null);
  }

  function handleSubmit(event) {
    event.preventDefault();
    resetError();
    let hasError = false;
    if (!email) {
      setEmailError('Please input valid email address.');
      hasError = true;
    }
    if (hasError) return;
    setLoading(true);
    firebase.resetPassword(email)
      .then(() => {
        setLoading(false);
        setShowInfoSnackbar(true);
        setTimeout(() => {
          history.push('/signin');
        }, 3000);
      }).catch((error) => {
        setServerError(error.message);
        setLoading(false);
      });
  }

  return (
    <Wrapper>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Reset password
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Enter your email to reset your password
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <Input id="email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            error={!!emailError}
            disabled={loading}
            onKeyDown={_ => setEmailError(null)}
            onChange={event => setEmail(event.target.value)}
          />
          <FormError error={emailError} />
        </FormControl>
        <FormError error={serverError} />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          mt={4}
          mb={2}>
          <ButtonProgress loading={loading} />
          Send reset password email
        </Button>
      </form>
      <InfoSnackbar open={showInfoSnackbar}
        message={"Password reset email has been sent"}
        onClose={_ => { setShowInfoSnackbar(false) }}
      />
    </Wrapper>
  );
}

export default withRouter(withFirebase(ResetPassword));
