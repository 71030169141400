import React from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  Grid,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  AlertCircle,
  // ExternalLink as RfExternalLink
} from "react-feather";
import { spacing } from "@material-ui/system";
import Loader from "../../components/Loader";
import MenuDialog from "../../components/dialogs/MenuDialog";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);
const Paper = styled(MuiPaper)(spacing);
// const ExternalLink = styled(RfExternalLink)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;
const TableCellContent = styled(TableCell)`
  min-width: 200px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
// const TableCellButton = styled(TableCell)`
//   min-width: 200px;
// `;
const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

class MenuList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      selectedMenu: null,
    }
  }

  handleOpenDialog = (menu) => {
    this.setState({ openDialog: true, selectedMenu: menu });
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  renderList = (menus) => {

    return (
      <React.Fragment>
        <CardContent mb={2}>
          <Grid container direction="column" alignItems="flex-end" spacing={3}>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                onClick={_ => this.handleOpenDialog(null)}>
                Create New Menu
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Categories</TableCell>
                  {/* <TableCell></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {menus.map(menu => (
                  <TableRowClickable hover key={menu.id}>
                    <TableCellContent component="th" scope="row"
                      onClick={_ => this.handleOpenDialog(menu)}>
                      {menu.name}
                    </TableCellContent>
                    <TableCellContent
                      onClick={_ => this.handleOpenDialog(menu)}>
                      {
                        menu.categories.length > 0 ?
                          menu.categories.map(c => c.name).join(', ') : 'None'
                      }
                    </TableCellContent>
                    {/* <TableCellButton align="right">
                      <Button
                        type="button"
                        color="primary"
                        onClick={_ => { }}>
                        <ExternalLink mr={2} />
                        Preview
                      </Button>
                    </TableCellButton> */}
                  </TableRowClickable>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </Paper>
      </React.Fragment>
    );
  }

  renderEmpty = () => {
    return (
      <CardContent mt={6} mb={6}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item>
            <AlertCircle size={96} color={"#CED4D9"} />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" gutterBottom>
              You haven't create any menu yet
              </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
              Set-up a menu for your restaurant and your customers can start order.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mt={3}
              onClick={_ => this.handleOpenDialog(null)}>
              Create a Menu
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    );
  }

  mutableMenu = () => {
    const { selectedMenu } = this.state;
    if (!selectedMenu) return null;
    const mutable = { ...selectedMenu };
    mutable.categories = [];
    selectedMenu.categories.map(
      category => mutable.categories.push({ ...category })
    );
    return mutable;
  }

  render() {

    const { categories, menus } = this.props;
    const { openDialog } = this.state;

    const selectedMenu = this.mutableMenu();

    return (
      <Card mb={6}>
        {
          menus.length > 0 ?
            this.renderList(menus) :
            this.renderEmpty()
        }
        <MenuDialog
          open={openDialog}
          menu={selectedMenu}
          categories={categories}
          onClose={this.handleCloseDialog} />
      </Card>
    );
  }
}

function Menus({ categories, menus }) {

  if (menus === null)
    return <Loader />;
  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom display="inline">
        Menus
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Setting</Typography>
        <Typography>Menu</Typography>
        <Typography>Menus</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MenuList categories={categories} menus={menus} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const categories = state.firestore.ordered.categories;
  const menus = state.firestore.ordered.menus;
  if (menus) {
    return {
      auth: state.firebase.auth,
      menus,
      categories: categories || [],
    }
  }
  return {
    auth: state.firebase.auth,
    categories: null,
    menus: null,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    const { auth } = props;
    return [
      {
        collection: 'merchants',
        doc: auth.uid,
        subcollections: [{
          collection: 'categories',
        }],
        storeAs: 'categories',
      },
      {
        collection: 'merchants',
        doc: auth.uid,
        subcollections: [{
          collection: 'menus',
        }],
        storeAs: 'menus',
      },
    ]
  })
)(Menus);