import firebase from './firebase';

function round(value) {
  if (typeof (value) == 'string') {
    if (isNaN(value)) {
      value = 0;
    } else {
      value = parseFloat(value);
    }
  } else if (typeof (value) != 'number') {
    value = 0;
  }
  return parseFloat(value.toFixed(2));
}

async function uploadImage(uid, image) {
  const { imageData, imageFile, imageUrl } = image;
  if (!imageData) return imageUrl;
  const storage_ref = firebase.storage().ref();
  let fileName = Date.now();
  if (imageFile.type === 'image/png') {
    fileName += '.png';
  } else if (imageFile.type === 'image/jpeg') {
    fileName += '.jpg';
  }
  const image_ref = storage_ref.child(`${uid}/images/${fileName}`);
  const snapshot = await image_ref.putString(
    imageData,
    'data_url',
    {
      contentType: imageFile.type,
    },
  );
  const image_url = await snapshot.ref.getDownloadURL();
  return image_url;
}

async function deleteImage(image) {
  const { imageUrl } = image;
  const image_ref = firebase.storage().refFromURL(imageUrl);
  try {
    await image_ref.delete();
  } catch (err) { }
}

function getOrderDescription(order) {
  const items = order.order_items;
  const records = [];
  for (let i = 0; i < items.length; i++) {
    const order = items[i];
    let record = order.item.title;
    if (order.selected_options.length > 0) {
      const selectedOptions = [];
      order.selected_options.forEach((selectObj) => {
        selectObj.options.forEach((option) => {
          selectedOptions.push(option.title);
        });
      });
      const options = selectedOptions.join(', ');
      if (options.length > 0) record += ' (' + options + ')';
    }
    record += ' x' + order.quantity;
    records.push(record);
  }
  return records.join(', ');
}

function capitalizeString(str) {
  let splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

function formatDateFull(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${month}/${day}/${year} ${hour}:${minute}:${second}`;
}

function formatDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

function formatTime(date) {
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${hour}:${minute}:${second}`;
}

function formatDateSimple(date) {
  const monthString = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const day = date.getDate();
  const month = date.getMonth();
  return `${monthString[month]} ${day}`;
}

// TODO, support timezone
// https://momentjs.com/timezone/
function sfDateString(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${year}/${month}/${day} GMT-0700`;
}

function sfTodayDateString() {
  const today = new Date();
  return sfDateString(today);
}

function sfYesterdayDateString() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return sfDateString(yesterday);
}

function sfWeekFirstDateString() {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  const firstDay = new Date();
  firstDay.setDate(diff);
  return sfDateString(firstDay);
}

function sfLastWeekFirstDateString() {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  const firstDay = new Date();
  firstDay.setDate(diff - 7);
  return sfDateString(firstDay);
}

function getTableOffsetWidth(width) {
  let offsetWidth = 0;
  if (width === 'lg' || width === 'xl') {
    offsetWidth = 282;
  } else if (width === 'md') {
    offsetWidth = 250;
  }
  return offsetWidth;
}

export {
  round,
  uploadImage,
  deleteImage,
  getOrderDescription,
  capitalizeString,
  formatDate,
  formatTime,
  formatDateFull,
  formatDateSimple,
  sfDateString,
  sfTodayDateString,
  sfYesterdayDateString,
  sfWeekFirstDateString,
  sfLastWeekFirstDateString,
  getTableOffsetWidth,
}