import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  InputLabel,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Switch,
  // FormLabel as MuiFormLabel,
  Grid as MuiGrid,
  IconButton,
  FormHelperText,
  Typography,
  Slide,
  // Tooltip,
} from "@material-ui/core";
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";
import { XCircle } from "react-feather";
import DialogTitleWithClose from './DialogTitleWithClose';
import ButtonProgress from "../ButtonProgress";
import ImageFileInput from '../ImageFileInput';
import ImageEditDialog from './ImageEditDialog';
import ErrorSnackbar from '../snackbars/ErrorSnackbar';
import DangerButton from '../buttons/DangerButton';
import ConfirmDialog from './ConfirmDialog';
import { uploadImage, deleteImage, round } from '../../utils';

const Grid = styled(MuiGrid)(spacing);

// const FormLabel = styled(MuiFormLabel)`
//   font-size: 10.5px;
// `;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModifierOption = ({
  index,
  loading,
  onTitleChange,
  onPriceChange,
  onImageChange,
  initialTitle,
  initialPrice,
  initialImage,
  imageData,
  onRemove,
  error,
}) => {

  const [title, setTitle] = useState(initialTitle ? initialTitle : "");
  const [price, setPrice] = useState(initialPrice ? initialPrice : "");

  const thumbnail = imageData || initialImage;

  return (
    <React.Fragment>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel htmlFor={`optionTitle${index}`} shrink>Option Title</InputLabel>
          <Input
            id={`optionTitle${index}`}
            placeholder={"New option title"}
            value={title}
            fullWidth
            readOnly={loading}
            error={error}
            onChange={event => {
              const newTitle = event.target.value;
              setTitle(newTitle);
              onTitleChange(index, newTitle)
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel htmlFor={`optionPrice${index}`} shrink>Price Added</InputLabel>
          <Input
            id={`optionPrice${index}`}
            type="number"
            placeholder={"0.0"}
            value={price}
            fullWidth
            readOnly={loading}
            onChange={event => {
              const newPrice = event.target.value;
              setPrice(newPrice);
              onPriceChange(index, newPrice)
            }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <ImageFileInput
          id={index}
          thumbnail={thumbnail}
          disabled={loading}
          onChange={onImageChange}
          iconSize={36}
          thumbnailSize={48} />
      </Grid>
      <Grid item xs={2}>
        {
          !!title ?
            <IconButton aria-label="remove" onClick={() => {
              // reset
              setTitle("");
              setPrice("");
              onRemove(index);
            }}>
              <XCircle />
            </IconButton> :
            null
        }
      </Grid>
    </React.Fragment>
  )
}

class ModifierForm extends React.Component {

  constructor(props) {
    super(props);

    const { modifier } = props;

    this.state = {
      name: modifier ? modifier.name : '',
      title: modifier ? modifier.title : '',
      variant: modifier ? !!modifier.variant : false,
      multipleChoice: modifier ? !!modifier.multiple_choice : false,
      multipleChoiceMin: modifier ? (modifier.multiple_choice_min || 0) : 0,
      multipleChoiceMax: modifier ? (modifier.multiple_choice_max || 1) : 1,
      options: modifier ? modifier.options : [],
      // edit image
      imageDialogIndex: -1,
      imageDialogOpen: false,
      // delete
      deleteDialogOpen: false,
      // errors
      nameError: false,
      titleError: false,
      optionError: false,
      showErrorSnackbar: false,
    }
  }

  resetError = () => {
    const { options } = this.state;
    for (let i = 0; i < options.length; i++) {
      options[i].error = false;
    }
    this.setState({
      nameError: false,
      titleError: false,
      options,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      name,
      title,
      variant,
      multipleChoice,
      multipleChoiceMin,
      multipleChoiceMax,
      options
    } = this.state;
    const { modifier, onCreateModifier, onUpdateModifier } = this.props;

    this.resetError();
    // check error
    let hasError = false;
    if (!name) {
      this.setState({ nameError: true });
      hasError = true;
    }
    if (!title) {
      this.setState({ titleError: true });
      hasError = true;
    }
    for (let i = 0; i < options.length; i++) {
      if (!options[i].title) {
        options[i].error = true;
        hasError = true;
      }
    }
    this.setState({ options });
    // check if we have at least one option
    if (options.length === 0) {
      this.setState({ optionError: true });
      hasError = true;
    }
    if (hasError) {
      this.setState({ showErrorSnackbar: true });
      return;
    }

    if (modifier) {
      onUpdateModifier({
        name,
        title,
        variant,
        multipleChoice,
        multipleChoiceMin,
        multipleChoiceMax,
        options,
      });
    } else {
      onCreateModifier({
        name,
        title,
        variant,
        multipleChoice,
        multipleChoiceMin,
        multipleChoiceMax,
        options,
      });
    }
  }

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ deleteDialogOpen: true });
  }

  handleDeleteConfirm = () => {
    this.setState({ deleteDialogOpen: false });
    const { onDeleteModifier } = this.props;
    onDeleteModifier();
  }

  handleDeleteCancel = () => {
    this.setState({ deleteDialogOpen: false });
  }

  handleVariantChange = () => {
    const { variant } = this.state;
    this.setState({ variant: !variant, multipleChoice: false });
  }

  handleMultipleChoiceChange = () => {
    const { multipleChoice } = this.state;
    this.setState({ multipleChoice: !multipleChoice, variant: false });
  }

  handleOptionTitleChange = (index, value) => {
    const { options } = this.state;
    if (options.length > index) {
      options[index].title = value;
    } else {
      options.push({
        title: value,
        price_added: 0.0,
        image: "",
      })
    }
    this.setState({ options, optionError: false });
  }

  handleOptionPriceChange = (index, value) => {
    const { options } = this.state;
    const price = round(value);
    if (options.length > index) {
      options[index].price_added = price;
    } else {
      options.push({
        title: "",
        price_added: price,
        image: "",
      })
    }
    this.setState({ options });
  }

  handleOptionImageChange = (index, file) => {
    const { options } = this.state;
    if (options.length > index) {
      options[index].image_file = file;
    } else {
      options.push({
        title: "",
        price_added: 0.0,
        image: "",
        image_file: file,
      })
    }
    this.setState({
      options,
      imageDialogOpen: true,
      imageDialogIndex: index,
    });
  }

  handleOptionImageEditCancel = () => {
    this.setState({
      imageDialogOpen: false,
      imageDialogIndex: -1,
    });
  }

  handleOptionImageEditSuccess = (index, imageData) => {
    const { options } = this.state;
    options[index].image_data = imageData;
    this.setState({
      options,
      imageDialogOpen: false,
      imageDialogIndex: -1,
    });
  }

  handleOptionRemove = (index) => {
    const { options } = this.state;
    options[index].mark_delete = true;
    this.setState({ options });
  }

  handleErrorDialogClose = () => {
    this.setState({ showErrorSnackbar: false });
  }

  renderOptions = () => {
    const { loading, deleting } = this.props;
    const { options, optionError } = this.state;
    const optionsComponent = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.mark_delete) continue;
      optionsComponent.push(
        <ModifierOption
          key={i}
          index={i}
          onTitleChange={this.handleOptionTitleChange}
          onPriceChange={this.handleOptionPriceChange}
          onImageChange={this.handleOptionImageChange}
          initialTitle={option.title}
          initialPrice={option.price_added}
          initialImage={option.image}
          imageData={option.image_data}
          onRemove={this.handleOptionRemove}
          loading={loading || deleting}
          error={optionError} />
      );
    }

    // check if input name for last option
    if (!options.length || !!options[options.length - 1].title) {
      // new option
      optionsComponent.push(
        <ModifierOption
          key={options.length}
          index={options.length}
          onTitleChange={this.handleOptionTitleChange}
          onPriceChange={this.handleOptionPriceChange}
          onImageChange={this.handleOptionImageChange}
          loading={loading || deleting}
          error={optionError} />
      );
    }
    return optionsComponent;
  }

  containInItemCount = () => {
    const { modifier, items } = this.props;
    if (!modifier) return 0;
    let count = 0;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      for (let j = 0; j < item.modifiers.length; j++) {
        if (item.modifiers[j].id === modifier.id) {
          count++;
          break;
        }
      }
    }
    return count;
  }

  render() {

    const { modifier, loading, deleting } = this.props;
    const {
      name,
      title,
      variant,
      multipleChoice,
      multipleChoiceMin,
      multipleChoiceMax,
      options,
      imageDialogOpen,
      imageDialogIndex,
      deleteDialogOpen,
      nameError,
      titleError,
      showErrorSnackbar,
    } = this.state;
    let imageFile = null;
    if (imageDialogOpen) {
      imageFile = options[imageDialogIndex].image_file;
    }

    const count = this.containInItemCount();

    return (
      <React.Fragment>
        <DialogContent dividers>
          <Grid container direction="row" spacing={6} alignItems="center" justify="flex-start">
            <Grid item xs={12}>
              <Typography variant="subtitle2">Details</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor='name' shrink>Name</InputLabel>
                <Input
                  id='name'
                  autoFocus
                  placeholder='Modifier name'
                  value={name}
                  fullWidth
                  readOnly={loading || deleting}
                  error={nameError}
                  onChange={event => this.setState({ name: event.target.value })}
                  onKeyDown={_ => this.setState({ nameError: false })}
                />
                <FormHelperText>Modifier name for your reference only</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor='title' shrink>Title</InputLabel>
                <Input
                  id='title'
                  placeholder='Modifier title'
                  value={title}
                  fullWidth
                  readOnly={loading || deleting}
                  error={titleError}
                  onChange={event => this.setState({ title: event.target.value })}
                  onKeyDown={_ => this.setState({ titleError: false })}
                />
                <FormHelperText>Modifier title display to your customers</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} mt={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={variant}
                    onClick={this.handleVariantChange}
                    value="variant"
                    disabled={loading}
                  />
                }
                label="Variant"
              />
              {/* <Tooltip title="Help Content">
                <IconButton aria-label="help">
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip> */}
            </Grid>
            <Grid item xs={12}>
              {/* <FormLabel component="legend">Multiple selection</FormLabel> */}
              <FormControlLabel
                control={
                  <Switch
                    checked={multipleChoice}
                    onClick={this.handleMultipleChoiceChange}
                    value="multipleChoice"
                    disabled={loading}
                  />
                }
                label="Multiple Choice"
              />
            </Grid>
            {
              multipleChoice ?
                <React.Fragment>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="minimalCount" shrink>Minimal Option Count</InputLabel>
                      <Input
                        type="number"
                        placeholder={"0"}
                        value={multipleChoiceMin}
                        fullWidth
                        readOnly={loading}
                        onChange={event => {
                          this.setState({ multipleChoiceMin: parseInt(event.target.value) });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="maximumCount" shrink>Maximum Option Count</InputLabel>
                      <Input
                        type="number"
                        placeholder={"1"}
                        value={multipleChoiceMax}
                        fullWidth
                        readOnly={loading}
                        onChange={event => {
                          this.setState({ multipleChoiceMax: parseInt(event.target.value) });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment> : null
            }
            <Grid item xs={12}>
              <Typography variant="subtitle2">Options</Typography>
            </Grid>
            {this.renderOptions()}
          </Grid>
          <ImageEditDialog
            id={imageDialogIndex}
            open={imageDialogOpen}
            imageFile={imageFile}
            onClose={this.handleOptionImageEditCancel}
            onEdit={this.handleOptionImageEditSuccess}
          />
          <ConfirmDialog
            title={"Delete Modifier"}
            open={deleteDialogOpen}
            onConfirm={this.handleDeleteConfirm}
            onClose={this.handleDeleteCancel}
            danger
            children={<Typography>
              Are you sure you want to delete the <b>{name}</b> modifier?
                Deleting this modifier will affect <b>{count} {count > 1 ? 'items' : 'item'}</b>.
              This action cannot be undone.
              </Typography>}
          />
          <ErrorSnackbar open={showErrorSnackbar}
            message={
              modifier ?
                "Please fix errors before editing this modifier" :
                "Please fix errors before creating new modifier"
            }
            onClose={this.handleErrorDialogClose}
          />
        </DialogContent>
        <DialogActions>
          {
            modifier ?
              <DangerButton
                variant="contained"
                disabled={loading || deleting}
                onClick={this.handleDelete}>
                <ButtonProgress loading={deleting} />
                Delete
              </DangerButton> :
              null
          }
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="outlined"
            disabled={loading || deleting}>
            <ButtonProgress loading={loading} />
            {modifier ? "Save Change" : "Create"}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

const ModifierDialog = ({ auth, firestore, open, onClose, modifier, items }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showError, setShowError] = useState(null);

  async function buildOptions(modifierData) {
    // upload option images and build option data
    const options = [];
    for (let i = 0; i < modifierData.options.length; i++) {
      const option = modifierData.options[i];
      if (option.mark_delete) continue;

      const image = await uploadImage(auth.uid, {
        imageData: option.image_data,
        imageFile: option.image_file,
        imageUrl: option.image || '',
      });

      const newOption = {
        title: option.title,
        price_added: option.price_added,
        image,
      };
      if (!!option.audio) {
        newOption['audio'] = option.audio;
      }

      options.push(newOption);
    }
    return options;
  }

  async function handleCreateModifier(modifierData) {

    setLoading(true);

    const options = await buildOptions(modifierData);

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('modifiers')
      .add({
        title: modifierData.title,
        name: modifierData.name,
        variant: modifierData.variant,
        multiple_choice: modifierData.multipleChoice,
        multiple_choice_min: modifierData.multipleChoiceMin,
        multiple_choice_max: modifierData.multipleChoiceMax,
        options,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleUpdateModifier(modifierData) {

    setLoading(true);

    const options = await buildOptions(modifierData);

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('modifiers')
      .doc(modifier.id)
      .set({
        title: modifierData.title,
        name: modifierData.name,
        variant: modifierData.variant,
        multiple_choice: modifierData.multipleChoice,
        multiple_choice_min: modifierData.multipleChoiceMin,
        multiple_choice_max: modifierData.multipleChoiceMax,
        options,
      }, {
        merge: true,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleDeleteModifier() {

    setDeleting(true);

    // remove it from items
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const modifiers = [];
      for (let j = 0; j < item.modifiers.length; j++) {
        if (item.modifiers[j].id === modifier.id) {
          continue;
        }
        modifiers.push(item.modifiers[j]);
      }
      await firestore.collection('merchants')
        .doc(auth.uid)
        .collection('items')
        .doc(item.id)
        .update({ modifiers });
    }

    // delete option images
    for (let i = 0; i < modifier.options.length; i++) {
      const option = modifier.options[i];
      option.image && await deleteImage({ imageUrl: option.image });
    }

    // remove document
    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('modifiers')
      .doc(modifier.id)
      .delete()
      .then(() => {
        setDeleting(false);
        onClose();
      })
      .catch((error) => {
        setDeleting(false);
        setShowError(error.message);
      });
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      scroll="paper"
      aria-labelledby="modifier-dialog-title">
      <DialogTitleWithClose id="modifier-dialog-title"
        disableClose={loading || deleting}
        onClose={onClose}>
        {
          modifier ?
            "Edit Modifier" :
            "Create New Modifier"
        }
      </DialogTitleWithClose>
      <ModifierForm
        modifier={modifier}
        items={items}
        loading={loading}
        deleting={deleting}
        onCreateModifier={handleCreateModifier}
        onUpdateModifier={handleUpdateModifier}
        onDeleteModifier={handleDeleteModifier} />
      {showError && <ErrorSnackbar open={!!showError}
        message={showError}
        onClose={_ => setShowError(null)}
      />}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(withFirestore(ModifierDialog));