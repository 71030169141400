import React from "react";

// import async from "../components/Async";

import {
  // BookOpen,
  // CheckSquare,
  // Grid,
  // Heart,
  // Layout,
  List,
  // Map,
  // PieChart,
  // Users,
} from "react-feather";

import {
  Apps,
  SignalCellularAlt,
  RestaurantMenu,
  // LocationOn,
  Loyalty,
} from "@material-ui/icons";

// Overview component
// const Overview = async(() => import("../pages/overview/Overview"));
import Overview from "../pages/overview/Overview";

// Orders components
import Orders from "../pages/orders/Orders";

// Menu components
import Menus from "../pages/inventory/Menus";
import Categories from "../pages/inventory/Categories";
import Items from "../pages/inventory/Items";
import Modifiers from "../pages/inventory/Modifiers";

// Coupon components
import Coupons from "../pages/coupon/Coupons";

// Integration components
import SquareIntegration from "../pages/integration/Square";

// Location component
// import Locations from "../pages/location";

// Components components
// const Avatars = async(() => import("../pages/components/Avatars"));
// const Badges = async(() => import("../pages/components/Badges"));
// const Buttons = async(() => import("../pages/components/Buttons"));
// const Cards = async(() => import("../pages/components/Cards"));
// const Chips = async(() => import("../pages/components/Chips"));
// const Dialogs = async(() => import("../pages/components/Dialogs"));
// const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
// const Lists = async(() => import("../pages/components/Lists"));
// const Menus2 = async(() => import("../pages/components/Menus"));
// const Progress = async(() => import("../pages/components/Progress"));
// const Snackbars = async(() => import("../pages/components/Snackbars"));
// const Tooltips = async(() => import("../pages/components/Tooltips"));

// Dashboards components
// const Dashboard = async(() => import("../pages/dashboards/Dashboard"));

// Forms components
// const Pickers = async(() => import("../pages/forms/Pickers"));
// const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
// const Selects = async(() => import("../pages/forms/Selects"));
// const TextFields = async(() => import("../pages/forms/TextFields"));

// Icons components
// const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
// const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
// const Blank = async(() => import("../pages/pages/Blank"));
// const Invoice = async(() => import("../pages/pages/Invoice"));
// const Pricing = async(() => import("../pages/pages/Pricing"));
// const Profile = async(() => import("../pages/pages/Profile"));
// const Settings = async(() => import("../pages/pages/Settings"));
// const Tasks = async(() => import("../pages/pages/Tasks"));
// const Projects = async(() => import("../pages/pages/Projects"));

// Tables components
// const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
// const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Chart components
// const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
// const Maps = async(() => import("../pages/maps/GoogleMaps"));

// Documentation
// const Docs = async(() => import("../pages/docs/Documentation"));

// Demo components
// import ScrollDialog from "../pages/demos/ScrollDialog";

const overviewRoutes = {
  id: "Overview",
  path: "/",
  header: "Report",
  icon: <SignalCellularAlt />,
  component: Overview,
  children: null
};

const ordersRoutes = {
  id: "Orders",
  path: "/orders",
  // header: "Orders",
  icon: <List />,
  // open: true,
  component: Orders,
  children: null,
}

const inventoryRoutes = {
  id: "Inventory",
  path: "/inventory",
  header: "Setting",
  icon: <RestaurantMenu />,
  // open: true,
  children: [
    {
      path: "/inventory/menus",
      name: "Menus",
      component: Menus,
    },
    {
      path: "/inventory/categories",
      name: "Categories",
      component: Categories,
    },
    {
      path: "/inventory/items",
      name: "Items",
      component: Items,
    },
    {
      path: "/inventory/modifiers",
      name: "Modifiers",
      component: Modifiers,
    },
  ],
}

const couponsRoutes = {
  id: "Coupon",
  path: "/coupon",
  icon: <Loyalty />,
  component: Coupons,
  children: null,
}

const integrationRoutes = {
  id: "Integration",
  path: "/integration",
  icon: <Apps />,
  component: Coupons,
  children: [
    {
      path: "/integration/square",
      name: "Square",
      component: SquareIntegration,
    },
  ],
}

// const locationsRoutes = {
//   id: "Locations",
//   path: "/locations",
//   icon: <LocationOn />,
//   component: Locations,
//   children: null,
// }

// const pagesRoutes = {
//   id: "Pages",
//   path: "/pages",
//   icon: <Layout />,
//   open: true,
//   children: [
//     {
//       path: "/pages/profile",
//       name: "Profile",
//       component: Profile
//     },
//     {
//       path: "/pages/settings",
//       name: "Settings",
//       component: Settings
//     },
//     {
//       path: "/pages/projects",
//       name: "Projects",
//       component: Projects,
//       badge: "New"
//     },
//     {
//       path: "/pages/invoice",
//       name: "Invoice",
//       component: Invoice
//     },
//     {
//       path: "/pages/pricing",
//       name: "Pricing",
//       component: Pricing
//     },
//     {
//       path: "/pages/tasks",
//       name: "Tasks",
//       component: Tasks,
//       badge: "New"
//     },
//     {
//       path: "/pages/blank",
//       name: "Blank Page",
//       component: Blank
//     }
//   ]
// };

// const documentationRoutes = {
//   id: "Documentation",
//   path: "/documentation",
//   icon: <BookOpen />,
//   component: Docs,
//   children: null
// };

// const componentsRoutes = {
//   id: "Components",
//   path: "/components",
//   header: "Elements",
//   icon: <Grid />,
//   children: [
//     {
//       path: "/components/avatars",
//       name: "Avatars",
//       component: Avatars
//     },
//     {
//       path: "/components/badges",
//       name: "Badges",
//       component: Badges
//     },
//     {
//       path: "/components/buttons",
//       name: "Buttons",
//       component: Buttons
//     },
//     {
//       path: "/components/cards",
//       name: "Cards",
//       component: Cards
//     },
//     {
//       path: "/components/chips",
//       name: "Chips",
//       component: Chips
//     },
//     {
//       path: "/components/dialogs",
//       name: "Dialogs",
//       component: Dialogs
//     },
//     {
//       path: "/components/expansion-panels",
//       name: "Expansion Panels",
//       component: ExpPanels
//     },
//     {
//       path: "/components/lists",
//       name: "Lists",
//       component: Lists
//     },
//     {
//       path: "/components/menus",
//       name: "Menus2",
//       component: Menus2
//     },
//     {
//       path: "/components/progress",
//       name: "Progress",
//       component: Progress
//     },
//     {
//       path: "/components/snackbars",
//       name: "Snackbars",
//       component: Snackbars
//     },
//     {
//       path: "/components/tooltips",
//       name: "Tooltips",
//       component: Tooltips
//     }
//   ]
// };

// const formsRoutes = {
//   id: "Forms",
//   path: "/forms",
//   icon: <CheckSquare />,
//   children: [
//     {
//       path: "/forms/pickers",
//       name: "Pickers",
//       component: Pickers
//     },
//     {
//       path: "/forms/selection-controls",
//       name: "Selection Controls",
//       component: SelectionCtrls
//     },
//     {
//       path: "/forms/selects",
//       name: "Selects",
//       component: Selects
//     },
//     {
//       path: "/forms/text-fields",
//       name: "Text Fields",
//       component: TextFields
//     }
//   ]
// };

// const tablesRoutes = {
//   id: "Tables",
//   path: "/tables",
//   icon: <List />,
//   children: [
//     {
//       path: "/tables/simple-table",
//       name: "Simple Table",
//       component: SimpleTable
//     },
//     {
//       path: "/tables/advanced-table",
//       name: "Advanced Table",
//       component: AdvancedTable
//     }
//   ]
// };

// const iconsRoutes = {
//   id: "Icons",
//   path: "/icons",
//   icon: <Heart />,
//   children: [
//     {
//       path: "/icons/material-icons",
//       name: "Material Icons",
//       component: MaterialIcons
//     },
//     {
//       path: "/icons/feather-icons",
//       name: "Feather Icons",
//       component: FeatherIcons
//     }
//   ]
// };

// const chartRoutes = {
//   id: "Charts",
//   path: "/charts",
//   icon: <PieChart />,
//   component: Chartjs,
//   children: null
// };

// const mapsRoutes = {
//   id: "Maps",
//   path: "/maps",
//   icon: <Map />,
//   component: Maps,
//   children: null
// };

// const demoRoutes = {
//   id: "Demo",
//   path: "/demo",
//   header: "Debug",
//   icon: <BookOpen />,
//   children: [
//     {
//       path: "/demo/scrolldialog",
//       name: "Scroll Dialog",
//       component: ScrollDialog,
//     },
//   ],
// }

export const dashboard = [
  overviewRoutes,
  ordersRoutes,
  inventoryRoutes,
  couponsRoutes,
  integrationRoutes,
  // locationsRoutes,
  // pagesRoutes,
  // documentationRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // demoRoutes,
];

export default [
  overviewRoutes,
  ordersRoutes,
  inventoryRoutes,
  couponsRoutes,
  integrationRoutes,
  // locationsRoutes,
  // pagesRoutes,
  // documentationRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // demoRoutes,
];
