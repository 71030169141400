import store from '../store';
import { setLocation } from '../../storage';
import * as types from '../constants';

export function setLocationWithDispatch(locationId) {

  // set storage location
  setLocation(locationId);

  const action = {
    type: types.SET_LOCATION,
    payload: locationId,
  };
  store.dispatch(action);
}