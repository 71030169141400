import * as types from '../constants';

const INITIAL_STATE = {
  currentLocation: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {

    case types.SET_LOCATION:
      return {
        ...state,
        currentLocation: action.payload
      }

    default:
      return state
  }
}
