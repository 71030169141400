import React, { useState } from 'react';
import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  Slide,
  InputAdornment,
  Select,
  MenuItem,
} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitleWithClose from './DialogTitleWithClose';
import ButtonProgress from "../ButtonProgress";
import ErrorSnackbar from '../snackbars/ErrorSnackbar';
import DangerButton from '../buttons/DangerButton';
import ConfirmDialog from './ConfirmDialog';
import { COUPON_USE_TYPE_ONETIME, DISCOUNT_TYPE_PERCENTAGE_OFF, DISCOUNT_TYPE_AMOUNT_OFF, COUPON_TYPE_PERMANENT } from '../../constants';
import { round } from '../../utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CouponForm extends React.Component {

  constructor(props) {
    super(props);

    const { coupon } = props;

    this.state = {
      code: coupon ? coupon.code : '',
      useType: coupon ? coupon.use_type : COUPON_USE_TYPE_ONETIME,
      discountType: coupon ? coupon.discount_type : DISCOUNT_TYPE_PERCENTAGE_OFF,
      discountValue: this.getCouponDiscountValue(coupon),
      // delete
      deleteDialogOpen: false,
      // errors
      codeError: false,
      discountError: false,
      showErrorSnackbar: false,
    }
  }

  getCouponDiscountValue = (coupon) => {
    if (coupon) {
      if (coupon.discount_type === DISCOUNT_TYPE_PERCENTAGE_OFF) {
        return parseInt(coupon.value * 100);
      } else if (coupon.discount_type === DISCOUNT_TYPE_AMOUNT_OFF) {
        return coupon.value;
      }
    }
    return 10;
  }

  resetError = () => {
    this.setState({
      codeError: false,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { code, discountValue, discountType, useType } = this.state;
    const { coupon, onCreateCoupon, onUpdateCoupon } = this.props;

    this.resetError();
    // check error
    let hasError = false;
    if (!code) {
      this.setState({ codeError: true });
      hasError = true;
    }
    if (discountValue <= 0 ||
      (discountType === DISCOUNT_TYPE_PERCENTAGE_OFF && discountValue > 100)) {
      this.setState({ discountValueError: true });
      hasError = true;
    }
    if (hasError) {
      this.setState({ showErrorSnackbar: true });
      return;
    }

    let value = round(discountValue);
    if (discountType === DISCOUNT_TYPE_PERCENTAGE_OFF)
      value = round(discountValue / 100);

    if (coupon) {
      onUpdateCoupon({
        value,
      });
    } else {
      onCreateCoupon({
        discount_type: discountType,
        use_type: useType,
        code,
        value,
      });
    }
  }

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ deleteDialogOpen: true });
  }

  handleDeleteConfirm = () => {
    this.setState({ deleteDialogOpen: false });
    const { onDeleteCoupon } = this.props;
    onDeleteCoupon();
  }

  handleDeleteCancel = () => {
    this.setState({ deleteDialogOpen: false });
  }

  handleErrorDialogClose = () => {
    this.setState({ showErrorSnackbar: false });
  }

  render() {

    const { coupon, loading, deleting } = this.props;
    const {
      code,
      useType,
      discountType,
      discountValue,
      deleteDialogOpen,
      codeError,
      discountValueError,
      showErrorSnackbar,
    } = this.state;

    return (
      <React.Fragment>
        <DialogContent dividers>
          <Grid container spacing={6} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography variant="subtitle2">Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={6} alignItems="center">
                <Grid item xs={7}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="use-type">Type of Use</InputLabel>
                    <Select
                      value={useType}
                      readOnly={loading || deleting || coupon != null}
                      onChange={event => this.setState({ useType: event.target.value })}
                    >
                      <MenuItem value={COUPON_USE_TYPE_ONETIME}>One Time Use</MenuItem>
                      <MenuItem value={COUPON_TYPE_PERMANENT}>Never Expire</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="discount-type">Discount Type</InputLabel>
                    <Select
                      value={discountType}
                      readOnly={loading || deleting || coupon != null}
                      onChange={event => this.setState({ discountType: event.target.value })}>
                      <MenuItem value={DISCOUNT_TYPE_PERCENTAGE_OFF}>Percentage Off</MenuItem>
                      <MenuItem value={DISCOUNT_TYPE_AMOUNT_OFF}>Amount Off</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='code' shrink>Code</InputLabel>
                    <Input
                      id='code'
                      autoFocus
                      placeholder='Coupon code'
                      value={code}
                      fullWidth
                      readOnly={loading || deleting || coupon != null} // cannot change coupon code since its key for doc
                      error={codeError}
                      onChange={event => this.setState({ code: event.target.value })}
                      onKeyDown={_ => this.setState({ codeError: false })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='discount-value' shrink>Discount</InputLabel>
                    <Input
                      id='discount-value'
                      type='number'
                      placeholder={
                        discountType === DISCOUNT_TYPE_PERCENTAGE_OFF ?
                          'Percentage' : 'Amount'
                      }
                      value={discountValue}
                      fullWidth
                      readOnly={loading || deleting}
                      error={discountValueError}
                      onChange={event => {
                        const { value } = event.target;
                        // if (discountType === DISCOUNT_TYPE_PERCENTAGE_OFF) {
                        //   const intValue = parseInt(value);
                        //   if (intValue) {
                        //     this.setState({ discountValue: parseInt(value) });
                        //   }
                        //   else if (value === '-' || value.length === 0) {
                        //     this.setState({ discountValue: 0 });
                        //   }
                        //   return;
                        // }
                        this.setState({ discountValue: value });
                      }}
                      onKeyDown={_ => this.setState({ discountValueError: false })}
                      startAdornment={
                        discountType === DISCOUNT_TYPE_AMOUNT_OFF ?
                          <InputAdornment position="start">$</InputAdornment> : null
                      }
                      endAdornment={
                        discountType === DISCOUNT_TYPE_PERCENTAGE_OFF ?
                          <InputAdornment position="end">%</InputAdornment> : null
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            coupon ?
              <DangerButton
                variant="contained"
                disabled={loading || deleting}
                onClick={this.handleDelete}>
                <ButtonProgress loading={deleting} />
                Delete
              </DangerButton> :
              null
          }
          <Button
            onClick={this.handleSubmit}
            variant="outlined"
            color="primary"
            disabled={loading || deleting}>
            <ButtonProgress loading={loading} />
            {coupon ? "Save Change" : "Create"}
          </Button>
        </DialogActions>
        <ConfirmDialog
          title={"Delete Coupon"}
          open={deleteDialogOpen}
          onConfirm={this.handleDeleteConfirm}
          onClose={this.handleDeleteCancel}
          danger
          children={<Typography>
            Are you sure you want to delete the <b>{code}</b> coupon?
            This action cannot be undone.
          </Typography>}
        />
        <ErrorSnackbar open={showErrorSnackbar}
          message={
            coupon ?
              "Please fix errors before editing this coupon" :
              "Please fix errors before creating new coupon"
          }
          onClose={this.handleErrorDialogClose}
        />
      </React.Fragment>
    );
  }
}

const CouponDialog = ({ auth, firestore, open, onClose, coupon }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showError, setShowError] = useState(null);

  async function handleCreateCoupon(couponData) {

    setLoading(true);

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('coupons')
      .doc(couponData.code)
      .set({
        use_count: 0,
        ...couponData,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleUpdateCoupon(couponData) {

    setLoading(true);

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('coupons')
      .doc(coupon.code)
      .update({
        ...couponData,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleDeleteCoupon() {

    setDeleting(true);

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('coupons')
      .doc(coupon.code)
      .delete()
      .then(() => {
        setDeleting(false);
        onClose();
      })
      .catch((error) => {
        setDeleting(false);
        setShowError(error.message);
      });
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="coupon-dialog-title">
      <DialogTitleWithClose id="coupon-dialog-title"
        disableClose={loading || deleting}
        onClose={onClose}>
        {
          coupon ?
            "Edit Coupon" :
            "Create New Coupon"
        }
      </DialogTitleWithClose>
      <CouponForm
        coupon={coupon}
        loading={loading}
        deleting={deleting}
        onCreateCoupon={handleCreateCoupon}
        onUpdateCoupon={handleUpdateCoupon}
        onDeleteCoupon={handleDeleteCoupon} />
      {showError && <ErrorSnackbar open={!!showError}
        message={showError}
        onClose={_ => setShowError(null)}
      />}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(withFirestore(CouponDialog));