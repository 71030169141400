import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Invalid from "../pages/auth/Invalid";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

function ChildRoutes({ layout: Layout, routes }) {
  return (
    <Layout>
      <Switch>
        {routes.map((category, index) =>
          category.children ? (
            // Route item with children
            category.children.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            ))
          ) : (
              // Route item without children
              <Route
                key={index}
                path={category.path}
                exact
                component={category.component}
              />
            )
        )}
        <Route
          path="/error"
          exact
          render={() => (<Page500 />)}
        />
        <Route
          render={() => (<Page404 />)}
        />
      </Switch>
    </Layout>
  );
}

const Routes = () => (
  <Router>
    <Switch>
      {/* Sign in route */}
      <Route
        path="/signin"
        exact
        render={() => (<AuthLayout><SignIn /></AuthLayout>)}
      />
      {/* Sign up route */}
      <Route
        path="/signup"
        exact
        render={() => (<AuthLayout><SignUp /></AuthLayout>)}
      />
      {/* Reset password route */}
      <Route
        path="/reset-password"
        exact
        render={() => (<AuthLayout><ResetPassword /></AuthLayout>)}
      />
      {/* Invalid route */}
      <Route
        path="/user-invalid"
        exact
        render={() => (<AuthLayout><Invalid /></AuthLayout>)}
      />
      {/* Dashboard routes */}
      <Route
        path="/*"
        exact
        component={() => (
          <ChildRoutes layout={DashboardLayout} routes={dashboardRoutes} />
        )}
      />
      {/* {childRoutes(DashboardLayout, dashboardRoutes)}
      {childRoutes(AuthLayout, authRoutes)} */}
      {/* 500 route */}
      <Route
        path="/error"
        exact
        render={() => (<AuthLayout><Page500 /></AuthLayout>)}
      />
      {/* 404 route */}
      <Route
        render={() => (<AuthLayout><Page404 /></AuthLayout>)}
      />
    </Switch>
  </Router>
);

export default Routes;
