import React, { Component } from "react";
import { connect } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";

import maTheme from "./theme";
import Routes from "./routes/Routes";
import { setLocationWithDispatch } from "./redux/actions/locationActions";
import { getLocation } from "./storage";

class App extends Component {

  componentDidMount() {
    // remove atom preloader
    const loader = document.getElementById('atom-preloader');
    loader.remove();

    // set storage location
    const locationId = getLocation();
    if (!!locationId) {
      setLocationWithDispatch(locationId);
    }
  }

  render() {

    const { theme } = this.props;

    return (
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    );
  }
}

export default connect(state => ({ theme: state.theme }))(App);
