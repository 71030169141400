import React from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  Grid,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { AlertCircle } from "react-feather";
import { spacing } from "@material-ui/system";
import Loader from "../../components/Loader";
import CategoryDialog from "../../components/dialogs/CategoryDialog";
import AddToMenuDialog from "../../components/dialogs/AddToMenuDialog";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;
const TableCellContent = styled(TableCell)`
  min-width: 200px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TableCellButton = styled(TableCell)`
  min-width: 200px;
`;
const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

class CategoryList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      openAddToMenuDialog: false,
      selectedCategory: null,
    }
  }

  handleOpenDialog = (category) => {
    this.setState({ openDialog: true, selectedCategory: category });
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleOpenAddToMenuDialog = (category) => {
    this.setState({ openAddToMenuDialog: true, selectedCategory: category });
  }

  handleCloseAddToMenuDialog = () => {
    this.setState({ openAddToMenuDialog: false });
  }

  renderList = (categories) => {

    return (
      <React.Fragment>
        <CardContent mb={2}>
          <Grid container direction="column" alignItems="flex-end" spacing={3}>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                onClick={_ => this.handleOpenDialog(null)}>
                Create New Category
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Items</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map(category => (
                  <TableRowClickable hover key={category.id}>
                    <TableCellContent component="th" scope="row"
                      onClick={_ => this.handleOpenDialog(category)}>
                      {category.name}
                    </TableCellContent>
                    <TableCellContent
                      onClick={_ => this.handleOpenDialog(category)}>
                      {category.title}
                    </TableCellContent>
                    <TableCellContent
                      onClick={_ => this.handleOpenDialog(category)}>
                      {
                        category.items.length > 0 ?
                          category.items.map(t => t.name).join(', ') : 'None'
                      }
                    </TableCellContent>
                    <TableCellButton align="right">
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={_ => this.handleOpenAddToMenuDialog(category)}>
                        Add to Menu
                      </Button>
                    </TableCellButton>
                  </TableRowClickable>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </Paper>
      </React.Fragment>
    );
  }

  renderEmpty = () => {
    return (
      <CardContent mt={6} mb={6}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item>
            <AlertCircle size={96} color={"#CED4D9"} />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" gutterBottom>
              You haven't create any category yet
              </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
              Categories make it easy for your customers to find menu items.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mt={3}
              onClick={_ => this.handleOpenDialog(null)}>
              Create a Category
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    );
  }

  mutableCategory = () => {
    const { selectedCategory } = this.state;
    if (!selectedCategory) return null;
    const mutable = { ...selectedCategory };
    mutable.items = [];
    selectedCategory.items.map(
      item => mutable.items.push({ ...item })
    );
    return mutable;
  }

  render() {

    const { categories, items, menus } = this.props;
    const { openDialog, openAddToMenuDialog } = this.state;

    const selectedCategory = this.mutableCategory();

    return (
      <Card mb={6}>
        {
          categories.length > 0 ?
            this.renderList(categories) :
            this.renderEmpty()
        }
        <CategoryDialog
          open={openDialog}
          category={selectedCategory}
          items={items}
          menus={menus}
          onClose={this.handleCloseDialog} />
        {openAddToMenuDialog && <AddToMenuDialog
          open={openAddToMenuDialog}
          category={selectedCategory}
          menus={menus}
          onClose={this.handleCloseAddToMenuDialog}
        />}
      </Card>
    );
  }
}

function Categories({ items, categories, menus }) {

  if (categories === null)
    return <Loader />;
  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom display="inline">
        Categories
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Setting</Typography>
        <Typography>Menu</Typography>
        <Typography>Categories</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CategoryList
            categories={categories}
            menus={menus}
            items={items} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const items = state.firestore.ordered.items;
  const categories = state.firestore.ordered.categories;
  const menus = state.firestore.ordered.menus;
  if (categories) {
    return {
      auth: state.firebase.auth,
      categories,
      menus: menus || [],
      items: items || [],
    }
  }
  return {
    auth: state.firebase.auth,
    categories: null,
    menus: null,
    items: null,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    const { auth } = props;
    return [
      {
        collection: 'merchants',
        doc: auth.uid,
        subcollections: [{
          collection: 'items',
        }],
        storeAs: 'items',
      },
      {
        collection: 'merchants',
        doc: auth.uid,
        subcollections: [{
          collection: 'categories',
        }],
        storeAs: 'categories',
      },
      {
        collection: 'merchants',
        doc: auth.uid,
        subcollections: [{
          collection: 'menus',
        }],
        storeAs: 'menus',
      },
    ]
  })
)(Categories);