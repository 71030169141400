import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  InputLabel,
  FormControl,
  InputAdornment,
  Grid,
  FormHelperText,
  Typography,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { uploadImage, deleteImage, round } from '../../utils';
import DialogTitleWithClose from './DialogTitleWithClose';
import ButtonProgress from "../ButtonProgress";
import ImageFileInput from '../ImageFileInput';
import ImageEditDialog from './ImageEditDialog';
import ErrorSnackbar from '../snackbars/ErrorSnackbar';
import DangerButton from '../buttons/DangerButton';
import ConfirmDialog from './ConfirmDialog';

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(16)}px);
`;
const TableCellContent = styled(TableCell)`
  min-width: 200px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ItemForm extends React.Component {

  constructor(props) {
    super(props);

    const { item } = props;

    this.state = {
      name: item ? item.name : '',
      title: item ? item.title : '',
      description: item ? item.description : '',
      price: item ? item.price : '',
      canAddNote: item ? item.can_add_note : false,
      // modifiers
      selectModifiers: this.initSelectModifiers(),
      // edit image
      imageFile: null,
      imageData: null,
      imageDialogOpen: false,
      // delete
      deleteDialogOpen: false,
      // errors
      nameError: false,
      titleError: false,
      priceError: false,
      showErrorSnackbar: false,
    }
  }

  initSelectModifiers = () => {
    const { item, modifiers } = this.props;
    return modifiers.map(modifier => {
      let selectIndex = -1;
      if (item) {
        for (let i = 0; i < item.modifiers.length; i++) {
          if (item.modifiers[i].id === modifier.id) {
            selectIndex = i + 1;
            break;
          }
        }
      }
      if (selectIndex > 0) {
        return { selected: true, selectIndex, ...modifier };
      }
      return { selected: false, selectIndex, ...modifier };
    });
  }

  resetError = () => {
    this.setState({
      nameError: false,
      titleError: false,
      priceError: false,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { name, title, description, selectModifiers, imageFile, imageData, price, canAddNote } = this.state;
    const { item, onCreateItem, onUpdateItem } = this.props;

    this.resetError();
    // check error
    let hasError = false;
    if (!name) {
      this.setState({ nameError: true });
      hasError = true;
    }
    if (!title) {
      this.setState({ titleError: true });
      hasError = true;
    }
    const itemPrice = round(price);
    if (!itemPrice || itemPrice < 0) {
      this.setState({ priceError: true });
      hasError = true;
    }
    if (hasError) {
      this.setState({ showErrorSnackbar: true });
      return;
    }

    // build modifiers
    const modifiers = selectModifiers
      .filter(m => m.selected) // remove unselect item
      .sort((a, b) => { return a.selectIndex - b.selectIndex; }) // sort by select index
      .map(m => { return { id: m.id, name: m.name } }); // only required fields

    if (item) {
      onUpdateItem({
        name,
        title,
        imageFile,
        imageData,
        description,
        modifiers,
        price: itemPrice,
        canAddNote,
      });
    } else {
      onCreateItem({
        name,
        title,
        imageFile,
        imageData,
        description,
        modifiers,
        price: itemPrice,
        canAddNote,
      });
    }
  }

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ deleteDialogOpen: true });
  }

  handleDeleteConfirm = () => {
    this.setState({ deleteDialogOpen: false });
    const { onDeleteItem } = this.props;
    onDeleteItem();
  }

  handleDeleteCancel = () => {
    this.setState({ deleteDialogOpen: false });
  }

  handleItemImageEditCancel = () => {
    this.setState({ imageDialogOpen: false });
  }

  handleItemImageEditSuccess = (_, imageData) => {
    this.setState({ imageData, imageDialogOpen: false });
  }

  handleErrorDialogClose = () => {
    this.setState({ showErrorSnackbar: false });
  }

  handleItemImageChange = (_, file) => {
    this.setState({ imageFile: file, imageDialogOpen: true });
  }

  handleCanAddNoteChange = () => {
    const { canAddNote } = this.state;
    this.setState({ canAddNote: !canAddNote });
  }

  handleItemModiferSelect = (id) => {
    const { selectModifiers } = this.state;
    const selectedModifiers = selectModifiers.filter(modifier => modifier.selected);
    const selectedCount = selectedModifiers.length;
    let removedIndex = -1;
    const newModifiers = selectModifiers.map(modifier => {
      if (id === modifier.id) {
        modifier.selected = !modifier.selected;
        if (modifier.selected) {
          modifier.selectIndex = selectedCount + 1;
        }
        else {
          removedIndex = modifier.selectIndex;
          modifier.selectIndex = -1;
        }
      }
      return { ...modifier };
    });
    if (removedIndex > 0) {
      newModifiers.forEach(modifier => {
        if (modifier.selected && modifier.selectIndex > removedIndex) {
          modifier.selectIndex -= 1;
        }
      });
    }
    this.setState({ selectModifiers: newModifiers });
  }

  renderModifiers = () => {
    const { selectModifiers } = this.state;

    return (
      <TableWrapper>
        <Table>
          <TableBody>
            {selectModifiers.map(modifier =>
              <TableRowClickable hover
                key={modifier.id}
                onClick={_ => { this.handleItemModiferSelect(modifier.id); }}>
                <TableCell padding="checkbox">
                  <Checkbox checked={modifier.selected} />
                </TableCell>
                <TableCell padding="none">
                  {modifier.selected ? modifier.selectIndex : ''}
                </TableCell>
                <TableCellContent component="th" scope="row">
                  {modifier.name}
                </TableCellContent>
                <TableCellContent>
                  {modifier.options.map(o => o.title).join(', ')}
                </TableCellContent>
              </TableRowClickable>)}
          </TableBody>
        </Table>
      </TableWrapper>
    );
  }

  containInCategoryCount = () => {
    const { item, categories } = this.props;
    if (!item) return 0;
    let count = 0;
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      for (let j = 0; j < category.items.length; j++) {
        if (category.items[j].id === item.id) {
          count++;
          break;
        }
      }
    }
    return count;
  }

  render() {

    const { item, loading, deleting } = this.props;
    const {
      name,
      title,
      description,
      price,
      canAddNote,
      imageDialogOpen,
      imageFile,
      imageData,
      deleteDialogOpen,
      nameError,
      titleError,
      priceError,
      showErrorSnackbar,
    } = this.state;

    const thumbnail = imageData || (item ? item.image : null);
    const count = this.containInCategoryCount();

    return (
      <React.Fragment>
        {/* <form onSubmit={this.handleSubmit} noValidate autoComplete="off" style={{ width: "100%" }}> */}
        <DialogContent dividers>
          <Grid container spacing={6} alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography variant="subtitle2">Details</Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Grid container direction="row" spacing={6} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='name' shrink>Name</InputLabel>
                    <Input
                      id='name'
                      autoFocus
                      placeholder='Item name'
                      value={name}
                      fullWidth
                      readOnly={loading || deleting}
                      error={nameError}
                      onChange={event => this.setState({ name: event.target.value })}
                      onKeyDown={_ => this.setState({ nameError: false })}
                    />
                    <FormHelperText>Item name for your reference only</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='title' shrink>Title</InputLabel>
                    <Input
                      id='title'
                      placeholder='Item title'
                      value={title}
                      fullWidth
                      readOnly={loading || deleting}
                      error={titleError}
                      onChange={event => this.setState({ title: event.target.value })}
                      onKeyDown={_ => this.setState({ titleError: false })}
                    />
                    <FormHelperText>Item title display to your customers</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='price' shrink>Base Price</InputLabel>
                    <Input
                      id='price'
                      type="number"
                      placeholder={"0.0"}
                      value={price}
                      fullWidth
                      readOnly={loading}
                      error={priceError}
                      onChange={event => this.setState({ price: event.target.value })}
                      onKeyDown={_ => this.setState({ priceError: false })}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='description' shrink>Description</InputLabel>
                    <Input
                      id='description'
                      placeholder='Description for the item'
                      value={description}
                      fullWidth
                      readOnly={loading || deleting}
                      onChange={event => this.setState({ description: event.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={canAddNote}
                        onClick={this.handleCanAddNoteChange}
                        value="can_add_note"
                        readOnly={loading || deleting}
                      />
                    }
                    label="Can add note"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Grid container
                spacing={0}
                alignItems="center"
                justify="center">
                <Grid item>
                  <ImageFileInput
                    id={0}
                    thumbnail={thumbnail}
                    disabled={loading}
                    onChange={this.handleItemImageChange}
                    iconSize={80}
                    thumbnailSize={132} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Modifiers</Typography>
            </Grid>
            <Grid item xs={12}>
              {this.renderModifiers()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            item ?
              <DangerButton
                variant="contained"
                disabled={loading || deleting}
                onClick={this.handleDelete}>
                <ButtonProgress loading={deleting} />
                Delete
              </DangerButton> :
              null
          }
          <Button
            onClick={this.handleSubmit}
            variant="outlined"
            color="primary"
            disabled={loading || deleting}>
            <ButtonProgress loading={loading} />
            {item ? "Save Change" : "Create"}
          </Button>
        </DialogActions>
        {/* </form> */}
        <ImageEditDialog
          id={0}
          open={imageDialogOpen}
          imageFile={imageFile}
          onClose={this.handleItemImageEditCancel}
          onEdit={this.handleItemImageEditSuccess}
        />
        <ConfirmDialog
          title={"Delete Item"}
          open={deleteDialogOpen}
          onConfirm={this.handleDeleteConfirm}
          onClose={this.handleDeleteCancel}
          danger
          children={<Typography>
            Are you sure you want to delete the <b>{name}</b> item?
            Deleting this item will affect <b>{count} {count > 1 ? 'categories' : 'category'}</b>.
            This action cannot be undone.
          </Typography>}
        />
        <ErrorSnackbar open={showErrorSnackbar}
          message={
            item ?
              "Please fix errors before editing this item" :
              "Please fix errors before creating new item"
          }
          onClose={this.handleErrorDialogClose}
        />
      </React.Fragment>
    );
  }
}

const ItemDialog = ({ auth, firestore, open, onClose, item, modifiers, categories }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showError, setShowError] = useState(null);

  async function handleCreateItem(itemData) {

    setLoading(true);

    const image = await uploadImage(auth.uid, {
      imageData: itemData.imageData,
      imageFile: itemData.imageFile,
      imageUrl: '',
    });

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('items')
      .add({
        title: itemData.title,
        name: itemData.name,
        description: itemData.description,
        image,
        price: itemData.price,
        modifiers: itemData.modifiers,
        can_add_note: itemData.canAddNote || false,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleUpdateItem(itemData) {

    setLoading(true);

    const image = await uploadImage(auth.uid, {
      imageData: itemData.imageData,
      imageFile: itemData.imageFile,
      imageUrl: item.image,
    });

    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('items')
      .doc(item.id)
      .set({
        title: itemData.title,
        name: itemData.name,
        description: itemData.description,
        image,
        price: itemData.price,
        modifiers: itemData.modifiers,
        can_add_note: itemData.canAddNote || false,
      }, {
        merge: true,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.message);
      });
  }

  async function handleDeleteItem() {

    setDeleting(true);

    // remove it from categories
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      const items = [];
      for (let j = 0; j < category.items.length; j++) {
        if (category.items[j].id === item.id) {
          continue;
        }
        items.push(category.items[j]);
      }
      await firestore.collection('merchants')
        .doc(auth.uid)
        .collection('categories')
        .doc(category.id)
        .update({ items });
    }

    // delete image
    item.image && await deleteImage({ imageUrl: item.image });

    // remove document
    firestore.collection('merchants')
      .doc(auth.uid)
      .collection('items')
      .doc(item.id)
      .delete()
      .then(() => {
        setDeleting(false);
        onClose();
      })
      .catch((error) => {
        setDeleting(false);
        setShowError(error.message);
      });
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="item-dialog-title">
      <DialogTitleWithClose id="item-dialog-title"
        disableClose={loading || deleting}
        onClose={onClose}>
        {
          item ?
            "Edit Item" :
            "Create New Item"
        }
      </DialogTitleWithClose>
      <ItemForm
        item={item}
        modifiers={modifiers}
        categories={categories}
        loading={loading}
        deleting={deleting}
        onCreateItem={handleCreateItem}
        onUpdateItem={handleUpdateItem}
        onDeleteItem={handleDeleteItem} />
      {showError && <ErrorSnackbar open={!!showError}
        message={showError}
        onClose={_ => setShowError(null)}
      />}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(withFirestore(ItemDialog));