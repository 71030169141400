import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const prodConfig = {
  apiKey: "AIzaSyDpdIXx5SiBti5FaGcVwYg2ahD6MDDBH94",
  authDomain: "reduce-main.firebaseapp.com",
  databaseURL: "https://reduce-main.firebaseio.com",
  projectId: "reduce-main",
  storageBucket: "reduce-main.appspot.com",
  messagingSenderId: "158289080229",
  appId: "1:158289080229:web:e8432b1227bce21b",
  measurementId: "G-YN4042Z6TL",
};

const devConfig = {
  apiKey: "AIzaSyAg1OyIeipQsACXtUVNrogYVQ289M0UC0A",
  authDomain: "reduce-test.firebaseapp.com",
  databaseURL: "https://reduce-test.firebaseio.com",
  projectId: "reduce-test",
  storageBucket: "reduce-test.appspot.com",
  messagingSenderId: "512280806702",
  appId: "1:512280806702:web:4cf9845154f00a70",
  measurementId: "G-GNR9YXCRQX",
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

firebase.initializeApp(config);

export { config };

export default firebase;
