import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Auth = ({ auth, profile, children }) => {

  if (auth.uid && profile.merchant === true)
    return <Redirect to="/" />;

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
}

export default connect(mapStateToProps)(Auth);
