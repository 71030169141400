import React from "react";
import { connect } from 'react-redux';
import styled from "styled-components";

import { Button as MuiButton, Menu, MenuItem } from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { formatDateSimple } from "../../utils";
import {
  setDateToday,
  setDateYesterday,
  setDateThisWeek,
  setDateLastWeek,
  setDateThisMonth,
  setDateLastMonth,
  setDateAll,
} from "../../redux/actions/dateActions";
import {
  DATE_FILTER_THIS_WEEK,
  DATE_FILTER_LAST_WEEK,
  DATE_FILTER_LAST_MONTH,
  DATE_FILTER_THIS_MONTH,
  DATE_FILTER_ALL
} from "../../constants";

const Button = styled(MuiButton)(spacing);

class DateRange extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleToday = () => {
    const { setDateToday } = this.props;
    setDateToday();
    this.handleClose();
  }

  handleYesterday = () => {
    const { setDateYesterday } = this.props;
    setDateYesterday();
    this.handleClose();
  }

  handleThisWeek = () => {
    const { setDateThisWeek } = this.props;
    setDateThisWeek();
    this.handleClose();
  }

  handleLastWeek = () => {
    const { setDateLastWeek } = this.props;
    setDateLastWeek();
    this.handleClose();
  }

  handleThisMonth = () => {
    const { setDateThisMonth } = this.props;
    setDateThisMonth();
    this.handleClose();
  }

  handleLastMonth = () => {
    const { setDateLastMonth } = this.props;
    setDateLastMonth();
    this.handleClose();
  }

  handleAll = () => {
    const { setDateAll } = this.props;
    setDateAll();
    this.handleClose();
  }

  renderDate() {
    const { currentDate } = this.props;
    if (currentDate === 'yesterday') {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return `Yesterday: ${formatDateSimple(yesterday)}`;
    }
    if (currentDate === DATE_FILTER_THIS_WEEK) {
      return 'This week';
    }
    if (currentDate === DATE_FILTER_LAST_WEEK) {
      return 'Last week';
    }
    if (currentDate === DATE_FILTER_THIS_MONTH) {
      return 'This month';
    }
    if (currentDate === DATE_FILTER_LAST_MONTH) {
      return 'Last month';
    }
    if (currentDate === DATE_FILTER_ALL) {
      return 'All date';
    }
    const today = new Date();
    return `Today: ${formatDateSimple(today)}`;
  }

  render() {
    const { anchorEl } = this.state;
    const { merchant } = this.props;
    const allDate = !!merchant && !!merchant.orders_all;
    return (
      <React.Fragment>
        <Button
          mr={2}
          variant="contained"
          size="small"
          color="secondary"
          aria-owns={anchorEl ? "date-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {this.renderDate()}
        </Button>
        <Menu
          id="date-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleToday}>Today</MenuItem>
          <MenuItem onClick={this.handleYesterday}>Yesterday</MenuItem>
          <MenuItem onClick={this.handleThisWeek}>This week</MenuItem>
          <MenuItem onClick={this.handleLastWeek}>Last week</MenuItem>
          {/* <MenuItem onClick={this.handleThisMonth}>This month</MenuItem> */}
          {/* <MenuItem onClick={this.handleLastMonth}>Last month</MenuItem> */}
          {
            allDate ? <MenuItem onClick={this.handleAll}>All date</MenuItem> : null
          }
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  merchant: state.firestore.data.merchant,
  currentDate: state.date.currentDate,
});

const mapActionsToProps = {
  setDateToday,
  setDateYesterday,
  setDateThisWeek,
  setDateLastWeek,
  setDateThisMonth,
  setDateLastMonth,
  setDateAll,
};

export default connect(mapStateToProps, mapActionsToProps)(DateRange);